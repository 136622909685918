import OneMarForm from '@models/mar/components/worksiteCompletion/OneMarForm';
import { Modal } from '@components/Modal';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Dispatch, SetStateAction, useState } from 'react';
import { formModalAddDocument } from '@models/mar/utils/datas';
import { ILinkedFile } from '@globalTypes/globalTypes';
import { DOCUMENT_TYPES } from '@utils/utils';

interface IModalAddOtherDocumentProps {
  setModalIsOpen: Dispatch<SetStateAction<boolean>>;
  onUploadDocument: (
    file: File,
    originalLinkedFile: ILinkedFile,
    indexDocument?: number,
    customName?: string
  ) => Promise<void | boolean>;
}

function ModalAddDocumentDepositOfApplication({
  setModalIsOpen,
  onUploadDocument,
}: IModalAddOtherDocumentProps) {
  const { t } = useTranslation();
  const [uploadLoading, setUploadLoading] = useState(false);

  const { watch } = useFormContext();

  const originalLinkedFile = {
    id: null,
    relation_ids: [2],
    relation_type: 'mar_worksite',
    file_type: DOCUMENT_TYPES.Other,
    isolate_file: false,
    file_url: null,
    file_hash: null,
    file_name: null,
    linked_entity_id: null,
    commentary: null,
    file: null,
    created_at: null,
    uploaded_at: null,
    created_by: null,
    is_deletable: null,
    status: 1,
    mandatory: true,
    document_analysis: null,
    custom_field_id: null,
    custom_title: null,
    custom_name: null,
  };

  const validateDocument = async () => {
    setUploadLoading(true);
    await onUploadDocument(
      watch('file_to_upload'),
      originalLinkedFile,
      undefined,
      watch('name_file_to_upload')
    );
    setUploadLoading(false);
  };

  return (
    <Modal
      title={`${t(
        'mar.worksite_creation.worksite_completion.folder_details.deposit_of_application.add_document'
      )}`}
      setIsModal={setModalIsOpen}
      sidebarVisible
      backgroundTransparent
      btnCancel
      textBtnConfirm={`${t('buttons.validate')}`}
      onConfirmClick={validateDocument}
      btnConfirmDisabled={
        !watch('name_file_to_upload') || !watch('file_to_upload')
      }
      isLoading={uploadLoading}
    >
      <div className="pt-4">
        <OneMarForm dataForm={formModalAddDocument(t)} />
      </div>
    </Modal>
  );
}

export default ModalAddDocumentDepositOfApplication;
