import { InputTypes } from '@utils/utils';
import { TFunction } from 'i18next';
import {
  IDataMarField,
  IDataMarForm,
  IInvoice,
  IProDevis,
  IWorksiteMar,
} from '@models/mar/utils/marTypes';
import {
  AUDITOR_TYPES,
  HOUSING_TYPES,
  MANDATORY_TYPES,
  OCCUPATION_TYPES,
  PERSON_TYPES,
  personTypesOptions,
} from '@models/mar/utils/enums';
import { IIncomes } from '@models/worksiteCreation/utils/types/SimulationTypes';
import { ILabelValue } from '../../../types/globalTypes';

const formHousingInformation = (
  t: TFunction,
  worksiteData: IWorksiteMar
): IDataMarForm => {
  const typeOfHousingOptions: ILabelValue[] = [
    {
      value: String(HOUSING_TYPES.HOUSE),
      label: t('worksite_creation.simulation.habitation.house'),
    },
    {
      value: String(HOUSING_TYPES.APARTMENT),
      label: t('worksite_creation.simulation.habitation.apartment'),
    },
  ];

  return {
    title: t(
      'mar.worksite_creation.worksite_completion.folder_details.typology_housing.general_information_housing.title'
    ),
    subtitle: `${t(
      'mar.worksite_creation.worksite_completion.folder_details.typology_housing.general_information_housing.subtitle'
    )}`,
    fields: [
      {
        title: `${t(
          'mar.worksite_creation.worksite_completion.folder_details.typology_housing.general_information_housing.type_of_housing'
        )}`,
        name: 'housing_type',
        type: InputTypes.SELECT,
        options: typeOfHousingOptions,
        optionSelected: worksiteData?.housing_type
          ? typeOfHousingOptions.find(
              (option) => option.value === String(worksiteData?.housing_type)
            )
          : undefined,
      },
      {
        title: `${t(
          'mar.worksite_creation.worksite_completion.folder_details.typology_housing.general_information_housing.housing_area'
        )}`,
        name: 'surface',
        type: InputTypes.NUMBER,
        defaultValue: worksiteData?.surface
          ? String(worksiteData?.surface)
          : undefined,
      },
      {
        title: `${t(
          'mar.worksite_creation.worksite_completion.folder_details.typology_housing.general_information_housing.tax_reference'
        )}`,
        name: 'tax_reference',
        type: InputTypes.NUMBER,
        defaultValue: worksiteData?.tax_reference || undefined,
      },
    ],
  };
};

const formCustomerInformation = (
  t: TFunction,
  worksiteData: IWorksiteMar
): IDataMarForm => {
  const occupationTypeOptions: ILabelValue[] = [
    {
      value: String(OCCUPATION_TYPES.OWNER_OCCUPANT),
      label: t('worksite_creation.simulation.owner'),
    },
    {
      value: String(OCCUPATION_TYPES.LANDLORD),
      label: t('worksite_creation.simulation.landlord_owner'),
    },
  ];

  return {
    title: t(
      'mar.worksite_creation.worksite_completion.folder_details.customer_information.household_information.title'
    ),
    fields: [
      {
        title: `${t(
          'mar.worksite_creation.worksite_completion.folder_details.customer_information.household_information.customer_quality'
        )}`,
        name: 'occupation_type',
        type: InputTypes.SELECT,
        options: occupationTypeOptions,
        optionSelected: worksiteData?.occupation_type
          ? occupationTypeOptions.find(
              (option) => option.value === String(worksiteData?.occupation_type)
            )
          : undefined,
      },
      {
        title: `${t(
          'mar.worksite_creation.worksite_completion.folder_details.customer_information.household_information.number_of_persons'
        )}`,
        name: 'persons',
        type: InputTypes.NUMBER,
        defaultValue: worksiteData?.persons
          ? String(worksiteData?.persons)
          : undefined,
      },
    ],
  };
};

const formIncomes = (
  t: TFunction,
  incomesOptionsArray: IIncomes[],
  worksiteData: IWorksiteMar
): IDataMarForm => {
  const incomesOptionsLabelValue = incomesOptionsArray.map((incomesOption) => ({
    value: incomesOption.value.toString(),
    label: `${incomesOption.title} ${incomesOption.label}`,
  }));

  return {
    title: t(
      'mar.worksite_creation.worksite_completion.folder_details.customer_information.household_income.title'
    ),
    subtitle: `${t(
      'mar.worksite_creation.worksite_completion.folder_details.customer_information.household_income.subtitle'
    )}`,
    fields: [
      {
        name: 'precarity_type',
        type: InputTypes.CHOICE,
        options: incomesOptionsLabelValue,
        optionSelected: worksiteData?.precarity_type
          ? incomesOptionsLabelValue.find(
              (option) => option.value === String(worksiteData?.precarity_type)
            )
          : undefined,
        fullWidth: true,
      },
    ],
  };
};

const formTaxHouseholdNumber = (
  t: TFunction,
  worksiteData: IWorksiteMar
): IDataMarForm => {
  const taxHouseholdNumberOptions: ILabelValue[] = [1, 2, 3, 4, 5].map(
    (value) => ({
      label: value.toString(),
      value: value.toString(),
    })
  );
  return {
    title: t(
      'mar.worksite_creation.worksite_completion.folder_details.customer_information.tax_household.title'
    ),
    fields: [
      {
        name: 'tax_household_number',
        type: InputTypes.SELECT,
        title: `${t(
          'mar.worksite_creation.worksite_completion.folder_details.customer_information.tax_household.number_of_tax_households'
        )}`,
        options: taxHouseholdNumberOptions,
        optionSelected: worksiteData?.tax_households
          ? {
              label: worksiteData.tax_households.length.toString(),
              value: worksiteData.tax_households.length.toString(),
            }
          : undefined,
      },
    ],
  };
};

const formTaxHouseholdDeclarant = (
  t: TFunction,
  worksiteData: IWorksiteMar,
  declarantNumber: number
): IDataMarForm => {
  return {
    title: `${t(
      'mar.worksite_creation.worksite_completion.folder_details.customer_information.declarant.title'
    )} n°${declarantNumber}`,
    fields: [
      {
        name: `tax_household_declarant_${declarantNumber}_firstname`,
        type: InputTypes.TEXT,
        title: `${t(
          'mar.worksite_creation.worksite_completion.folder_details.customer_information.declarant.firstname'
        )}`,
        defaultValue: worksiteData?.tax_households
          ? worksiteData?.tax_households[declarantNumber - 1]?.firstname
          : undefined,
      },
      {
        name: `tax_household_declarant_${declarantNumber}_lastname`,
        type: InputTypes.TEXT,
        title: `${t(
          'mar.worksite_creation.worksite_completion.folder_details.customer_information.declarant.lastname'
        )}`,
        defaultValue: worksiteData?.tax_households
          ? worksiteData?.tax_households[declarantNumber - 1]?.lastname
          : undefined,
      },
    ],
  };
};
/* "audit_information": {
  "title": "Informations de l'audit",
    "audit_number": "Numéro de l'audit",
    "date_of_technical_visit": "Date de la visite technique",
    "date_of_audit": "Date de réalisation de l'audit",
    "date_of_audit_validity": "Date de validité de l'audit (facultatif)",
    "who_performed_audit": "Qui a réalisé l'audit ?"
} */

const formInformationEnergyAudit = (
  t: TFunction,
  worksiteData: IWorksiteMar
): IDataMarForm => {
  return {
    title: t(
      'mar.worksite_creation.worksite_completion.folder_details.energy_audit.audit_information.title'
    ),
    fields: [
      {
        title: `${t(
          'mar.worksite_creation.worksite_completion.folder_details.energy_audit.audit_information.audit_number'
        )}`,
        name: 'audit_number',
        type: InputTypes.TEXT,
      },
      {
        title: `${t(
          'mar.worksite_creation.worksite_completion.folder_details.energy_audit.audit_information.visit_date'
        )}`,
        name: 'visit_date',
        type: InputTypes.DATE,
        defaultValue: worksiteData.audit?.visit_date || undefined,
      },
      {
        title: `${t(
          'mar.worksite_creation.worksite_completion.folder_details.energy_audit.audit_information.report_date'
        )}`,
        name: 'report_date',
        type: InputTypes.DATE,
        defaultValue: worksiteData.audit?.report_date || undefined,
      },
      {
        title: `${t(
          'mar.worksite_creation.worksite_completion.folder_details.energy_audit.audit_information.validity_date'
        )}`,
        name: 'validity_date',
        type: InputTypes.DATE,
        defaultValue: worksiteData.audit?.validity_date || undefined,
      },
    ],
  };
};

const formAuditorPerformedBy = (
  t: TFunction,
  defaultOption: string | undefined
): IDataMarForm => {
  const options: ILabelValue[] = [
    {
      value: AUDITOR_TYPES.ENGINEERING_OFFICE,
      label: t(
        'mar.worksite_creation.worksite_completion.folder_details.energy_audit.audit_information.who_options.engineering_office'
      ),
    },
    {
      value: AUDITOR_TYPES.MYSELF,
      label: t(
        'mar.worksite_creation.worksite_completion.folder_details.energy_audit.audit_information.who_options.myself'
      ),
    },
    {
      value: AUDITOR_TYPES.COLLABORATOR,
      label: t(
        'mar.worksite_creation.worksite_completion.folder_details.energy_audit.audit_information.who_options.collaborator'
      ),
    },
  ];

  const optionSelected = options.find(
    (option) => option.value === defaultOption
  );

  return {
    title: t(
      'mar.worksite_creation.worksite_completion.folder_details.energy_audit.auditor.title'
    ),
    fields: [
      {
        title: `${t(
          'mar.worksite_creation.worksite_completion.folder_details.energy_audit.audit_information.who_performed_audit'
        )}`,
        name: 'audit_performed_by',
        type: InputTypes.SELECT,
        options,
        optionSelected,
      },
    ],
  };
};
const formAuditDocument = (t: TFunction): IDataMarForm => {
  return {
    title: t(
      'mar.worksite_creation.worksite_completion.folder_details.energy_audit.add_audit_doc'
    ),
    fields: [
      {
        name: 'file',
        type: InputTypes.DOCUMENT,
        fullWidth: true,
      },
    ],
  };
};

const formHousingAnalysis = (
  t: TFunction,
  worksiteData: IWorksiteMar,
  defaultValueFirstVisitDate: string | undefined
): IDataMarForm[] => {
  return [
    {
      title: t(
        'mar.worksite_creation.worksite_completion.folder_details.housing_analysis.first_visit_date_mar'
      ),
      fields: [
        {
          name: 'first_visit_date_mar',
          type: InputTypes.DATE,
          defaultValue: worksiteData.visit_date || defaultValueFirstVisitDate,
        },
      ],
    },
    {
      title: t(
        'mar.worksite_creation.worksite_completion.folder_details.housing_analysis.unfit'
      ),
      fields: [
        {
          name: 'unfit',
          type: InputTypes.BOOL,
          fullWidth: true,
        },
      ],
    },
    {
      title: t(
        'mar.worksite_creation.worksite_completion.folder_details.housing_analysis.enhancement'
      ),
      fields: [
        {
          name: 'enhancement',
          type: InputTypes.BOOL,
          fullWidth: true,
        },
      ],
    },
    {
      title: t(
        'mar.worksite_creation.worksite_completion.folder_details.housing_analysis.adaptation'
      ),
      fields: [
        {
          name: 'adaptation',
          type: InputTypes.BOOL,
          fullWidth: true,
        },
      ],
    },
    {
      title: t(
        'mar.worksite_creation.worksite_completion.folder_details.housing_analysis.summary_grid_file'
      ),
      fields: [
        {
          name: 'summary_grid_file',
          type: InputTypes.DOCUMENT,
          fullWidth: true,
        },
      ],
    },
  ];
};

const formScenariosAndCalculations = (
  t: TFunction,
  worksiteData: IWorksiteMar,
  energeticClassSelect: string | undefined,
  scenarioNumber: number | undefined,
  needExemption: boolean | undefined,
  stepTypeForm: number | undefined
): IDataMarForm => {
  const isInit = scenarioNumber === undefined;
  const prefix =
    scenarioNumber !== undefined ? `scenario.${scenarioNumber}.` : '';

  // Options pour les sélecteurs de classes énergétiques
  const energyClassOptions = ['A', 'B', 'C', 'D', 'E', 'F', 'G'].map(
    (label) => ({ label, value: label })
  );

  const stepTypeOptions: ILabelValue[] = [
    {
      label: t(
        'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.one_scenario.one_step'
      ),
      value: '1',
    },
    {
      label: t(
        'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.one_scenario.multiple_steps'
      ),
      value: '2',
    },
  ];

  const getStepTypeToUse = () => {
    if (stepTypeForm) {
      return Number(stepTypeForm);
    }
    if (scenarioNumber !== undefined) {
      return worksiteData?.scenarios[scenarioNumber]?.step_type;
    }
    return undefined;
  };

  const stepTypeToUse = getStepTypeToUse();

  const stepTypeOptionSelected =
    stepTypeToUse !== undefined
      ? stepTypeOptions.find((option) => Number(option.value) === stepTypeToUse)
      : undefined;

  // Champs transversaux pour les scénarios initiaux et individuels
  const transversalFields: IDataMarField[] = [
    {
      title: t(
        'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.initial_situation.primary_energy_consumption'
      ),
      name: `${prefix}cep`,
      type: InputTypes.NUMBER,
    },
    {
      title: t(
        'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.initial_situation.final_energy_consumption'
      ),
      name: `${prefix}cef`,
      type: InputTypes.NUMBER,
    },
    {
      title: t(
        'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.initial_situation.greenhouse_gas_emissions'
      ),
      name: `${prefix}eges`,
      type: InputTypes.NUMBER,
    },
    {
      title: t(
        'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.initial_situation.performance_energetic_class'
      ),
      name: `${prefix}energy_class`,
      type: InputTypes.SELECT,
      options: energyClassOptions,
      optionSelected: energeticClassSelect
        ? { label: energeticClassSelect, value: energeticClassSelect }
        : undefined,
      disabled: !energeticClassSelect,
    },
  ];

  // Champ spécifique pour l'aire de surface de référence
  const referenceSurfaceAreaField: IDataMarField = {
    title: t(
      'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.initial_situation.reference_surface_area'
    ),
    name: `${prefix}surface`,
    type: InputTypes.NUMBER,
    fullWidth: true,
  };

  // Champs pour les scénarios individuels
  let fieldsToUse: IDataMarField[];
  if (isInit) {
    fieldsToUse = [...transversalFields, referenceSurfaceAreaField];
  } else {
    fieldsToUse = [
      {
        title: t(
          'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.selected_scenario'
        ),
        name: `${prefix}selected`,
        type: InputTypes.CHECK_TOGGLE,
        fullWidth: true,
      },
      {
        title: t(
          'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.one_scenario.steps_scenario'
        ),
        name: `${prefix}step_type`,
        type: InputTypes.SELECT,
        options: [
          {
            label: t(
              'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.one_scenario.one_step'
            ),
            value: '1',
          },
          {
            label: t(
              'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.one_scenario.multiple_steps'
            ),
            value: '2',
          },
        ],
        optionSelected: stepTypeOptionSelected
          ? {
              label: stepTypeOptionSelected.label,
              value: stepTypeOptionSelected.value.toString(),
            }
          : undefined,
        fullWidth: true,
      },
      ...transversalFields,
    ];

    if (needExemption) {
      fieldsToUse.push(
        {
          title: t(
            'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.one_scenario.exemption'
          ),
          name: `${prefix}exemption`,
          type: InputTypes.CHECK_BOOL,
        },
        {
          name: `${prefix}exemption_file`,
          type: InputTypes.DOCUMENT,
          placeholder: `${t(
            'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.one_scenario.receipt_facultative'
          )}`,
        },
        {
          title: t(
            'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.one_scenario.type_of_exemption'
          ),
          name: `${prefix}exemption_message`,
          type: InputTypes.TEXT,
          fullWidth: true,
        }
      );
    }
  }

  return {
    title: isInit
      ? t(
          'mar.worksite_creation.worksite_completion.folder_details.scenarios_and_calculations.initial_situation.title'
        )
      : undefined,
    fields: fieldsToUse,
  };
};

const formQuote = (
  t: TFunction,
  proIndex: number,
  quoteIndex: number,
  quoteSavedData: IProDevis | undefined,
  formDate: string,
  minDate: Date
): IDataMarForm => {
  const prefix = `pros.${proIndex}.quotes.${quoteIndex}.`;
  return {
    fields: [
      {
        title: t(
          'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.quote_section.add_quote'
        ),
        name: `${prefix}file`,
        type: InputTypes.DOCUMENT,
        fullWidth: true,
      },
      {
        title: t(
          'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.quote_section.quote_selected'
        ),
        name: `${prefix}selected_devis`,
        type: InputTypes.CHECK_TOGGLE,
        fullWidth: true,
      },
      {
        title: t(
          'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.quote_section.date_of_quote'
        ),
        name: `${prefix}date`,
        type: InputTypes.DATE,
        fullWidth: true,
        minDate,
        defaultValue: quoteSavedData?.date || formDate || undefined,
      },
    ],
  };
};

const formAdministrativeMandate = (
  t: TFunction,
  worksiteData: IWorksiteMar,
  indexMandatory: number,
  personType: string,
  sameAsProxy: boolean,
  type: number
): IDataMarForm => {
  const personTypeFieldName = `mandataires.${indexMandatory}.person_type`;
  const sameAsProxyFieldName = `mandataires.${indexMandatory}.same_as_proxy`;

  const defaultPersonType = worksiteData?.mandataires?.[indexMandatory]
    ? personTypesOptions(t)[
        worksiteData.mandataires[indexMandatory]?.entity_id
          ? 'LEGAL'
          : 'NATURAL'
      ]
    : undefined;

  const fieldsToUse: IDataMarField[] = [
    {
      name: personTypeFieldName,
      title: t(
        'mar.worksite_creation.worksite_completion.folder_details.mandate.mandatory_type'
      ),
      type: InputTypes.SELECT,
      options: [
        {
          label: personTypesOptions(t).LEGAL.label,
          value: personTypesOptions(t).LEGAL.value,
        },
        {
          label: personTypesOptions(t).NATURAL.label,
          value: personTypesOptions(t).NATURAL.value,
        },
      ],
      optionSelected: defaultPersonType,
      fullWidth: type !== MANDATORY_TYPES.FINANCIER,
    },
  ];

  if (type === MANDATORY_TYPES.FINANCIER) {
    fieldsToUse.push({
      title: t(
        'mar.worksite_creation.worksite_completion.folder_details.mandate.anah_number'
      ),
      name: `mandataires.${indexMandatory}.anah_number`,
      type: InputTypes.NUMBER,
    });
  }

  fieldsToUse.push(
    {
      name: `mandataires.${indexMandatory}.civility`,
      type: InputTypes.RADIO_CIVILITY,
      fullWidth: true,
    },
    {
      title: t('forms.firstname.placeholder'),
      name: `mandataires.${indexMandatory}.firstname`,
      type: InputTypes.TEXT,
    },
    {
      title: t('forms.lastname.placeholder'),
      name: `mandataires.${indexMandatory}.lastname`,
      type: InputTypes.TEXT,
    },
    {
      title: t('forms.email.placeholder'),
      name: `mandataires.${indexMandatory}.email`,
      type: InputTypes.TEXT,
    },
    {
      title: t('forms.phone.placeholder'),
      name: `mandataires.${indexMandatory}.phone_number`,
      type: InputTypes.NUMBER,
    },
    {
      title: t(
        'mar.worksite_creation.worksite_completion.folder_details.mandate.quality'
      ),
      name: `mandataires.${indexMandatory}.function`,
      type: InputTypes.TEXT,
      fullWidth: true,
    }
  );

  if (personType === PERSON_TYPES.NATURAL) {
    fieldsToUse.push(
      {
        title: t('forms.address.placeholder'),
        name: `mandataires.${indexMandatory}.address`,
        type: InputTypes.TEXT,
        fullWidth: true,
      },
      {
        title: t('forms.address.manual.zip'),
        name: `mandataires.${indexMandatory}.zipcode`,
        type: InputTypes.NUMBER,
      },
      {
        title: t('forms.address.manual.city'),
        name: `mandataires.${indexMandatory}.city`,
        type: InputTypes.TEXT,
      }
    );
  }

  const defaultSameAsProxy = () => {
    if (
      type !== MANDATORY_TYPES.ADMINISTRATIF_AIDE ||
      !worksiteData?.mandataires?.[indexMandatory]
    ) {
      return undefined;
    }

    if (
      worksiteData?.mandataires?.[indexMandatory]?.firstname ===
        worksiteData?.mandataires?.[indexMandatory - 1]?.firstname &&
      worksiteData?.mandataires?.[indexMandatory]?.lastname ===
        worksiteData?.mandataires?.[indexMandatory - 1]?.lastname &&
      worksiteData?.mandataires?.[indexMandatory]?.email ===
        worksiteData?.mandataires?.[indexMandatory - 1]?.email &&
      worksiteData?.mandataires?.[indexMandatory]?.phone_number ===
        worksiteData?.mandataires?.[indexMandatory - 1]?.phone_number
    ) {
      return 'true';
    }

    return 'false';
  };

  const sameAsProxyField = {
    title: t(
      'mar.worksite_creation.worksite_completion.folder_details.mandate.same_mandatory'
    ),
    name: sameAsProxyFieldName,
    type: InputTypes.CHECK_TOGGLE,
    defaultValue: defaultSameAsProxy(),
    fullWidth: true,
  };

  if (type === MANDATORY_TYPES.ADMINISTRATIF_AIDE) {
    fieldsToUse.unshift(sameAsProxyField);
  }

  let title = '';
  switch (type) {
    case MANDATORY_TYPES.ADMINISTRATIF_AIDE:
      title = t(
        'mar.worksite_creation.worksite_completion.folder_details.mandate.proxy_for_signing_commitments'
      );
      break;
    case MANDATORY_TYPES.FINANCIER:
      title = t(
        'mar.worksite_creation.worksite_completion.folder_details.mandate.mandate_financial'
      );
      break;
    case MANDATORY_TYPES.ADMINISTRATIF_SIGNATURE:
    default:
      title = t(
        'mar.worksite_creation.worksite_completion.folder_details.mandate.mandate_submit_online_application'
      );
      break;
  }

  return {
    title,
    fields: sameAsProxy ? [sameAsProxyField] : fieldsToUse,
  };
};

const formCheckFieldsMandatory = (
  t: TFunction,
  indexMandatory: number
): IDataMarForm => {
  return {
    fields: [
      {
        title: t(
          'mar.worksite_creation.worksite_completion.folder_details.mandate.aid_request'
        ),
        name: `mandataires.${indexMandatory}.aid_request`,
        type: InputTypes.CHECK_BOOL,
        checkOrTogglePosition: 'left',
        fullWidth: true,
      },
      {
        title: t(
          'mar.worksite_creation.worksite_completion.folder_details.mandate.payment_request'
        ),
        name: `mandataires.${indexMandatory}.payment_request`,
        type: InputTypes.CHECK_BOOL,
        checkOrTogglePosition: 'left',
        fullWidth: true,
      },
    ],
  };
};

const formModalAddDocument = (t: TFunction): IDataMarForm => {
  return {
    fields: [
      {
        title: t(
          'mar.worksite_creation.worksite_completion.folder_details.deposit_of_application.document_name'
        ),
        name: `name_file_to_upload`,
        type: InputTypes.TEXT,
        fullWidth: true,
      },
      {
        title: t(
          'mar.worksite_creation.worksite_completion.folder_details.deposit_of_application.choose_document'
        ),
        name: `file_to_upload`,
        type: InputTypes.DOCUMENT,
        fullWidth: true,
      },
    ],
  };
};

const formGrantNotification = (
  t: TFunction,
  worksiteData: IWorksiteMar
): IDataMarForm[] => {
  return [
    {
      title: t(
        'mar.worksite_creation.worksite_completion.folder_details.notification_of_grant.add_grant_agreement'
      ),
      fields: [
        {
          name: 'file_grant_notification',
          type: InputTypes.DOCUMENT,
          fullWidth: true,
        },
      ],
    },
    {
      title: t(
        'mar.worksite_creation.worksite_completion.folder_details.notification_of_grant.date_of_agreement'
      ),
      fields: [
        {
          name: 'agreement_date',
          type: InputTypes.DATE,
          defaultValue: worksiteData.agreement_date || undefined,
          fullWidth: true,
        },
      ],
    },
    {
      title: t(
        'mar.worksite_creation.worksite_completion.folder_details.notification_of_grant.confirmed_bonus_amount'
      ),
      fields: [
        {
          name: 'prime_amount',
          type: InputTypes.NUMBER,
          fullWidth: true,
        },
      ],
    },
  ];
};

const formWorkInProgress = (
  t: TFunction,
  worksiteData: IWorksiteMar
): IDataMarForm[] => {
  return [
    {
      title: t(
        'mar.worksite_creation.worksite_completion.folder_details.work_in_progress.site_dates.title'
      ),
      fields: [
        {
          name: 'start_date',
          title: t(
            'mar.worksite_creation.worksite_completion.folder_details.work_in_progress.site_dates.start_date'
          ),
          type: InputTypes.DATE,
          defaultValue: worksiteData.start_date || undefined,
        },
        {
          name: 'end_date',
          title: t(
            'mar.worksite_creation.worksite_completion.folder_details.work_in_progress.site_dates.end_date'
          ),
          type: InputTypes.DATE,
          defaultValue: worksiteData.end_date || undefined,
        },
      ],
    },
  ];
};

const formInvoice = (
  t: TFunction,
  indexInvoice: number,
  invoiceData: IInvoice | undefined
): IDataMarForm => {
  return {
    fields: [
      {
        title: t(
          'mar.worksite_creation.worksite_completion.folder_details.end_of_work.add_one_invoice'
        ),
        name: `factures.${indexInvoice}.file`,
        type: InputTypes.DOCUMENT,
        fullWidth: true,
      },
      {
        title: t(
          'mar.worksite_creation.worksite_completion.folder_details.end_of_work.invoice_date'
        ),
        name: `factures.${indexInvoice}.date`,
        type: InputTypes.DATE,
        defaultValue: invoiceData?.date || undefined,
        fullWidth: true,
      },
    ],
  };
};

const formAfterWork = (
  t: TFunction,
  worksiteData: IWorksiteMar
): IDataMarForm[] => {
  return [
    {
      title: t(
        'mar.worksite_creation.worksite_completion.folder_details.after_work.final_visit.title'
      ),
      fields: [
        {
          title: t(
            'mar.worksite_creation.worksite_completion.folder_details.after_work.final_visit.visit_date'
          ),
          name: 'final_visit_date',
          type: InputTypes.DATE,
          defaultValue: worksiteData.final_visit_date || undefined,
          fullWidth: true,
        },
      ],
    },
    {
      title: t(
        'mar.worksite_creation.worksite_completion.folder_details.after_work.add_report.title'
      ),
      fields: [
        {
          name: 'report_file',
          type: InputTypes.DOCUMENT,
          fullWidth: true,
        },
        {
          title: t(
            'mar.worksite_creation.worksite_completion.folder_details.after_work.add_report.report_date'
          ),
          name: 'report_date',
          type: InputTypes.DATE,
          defaultValue: worksiteData.report_date || undefined,
          fullWidth: true,
        },
      ],
    },
    {
      title: t(
        'mar.worksite_creation.worksite_completion.folder_details.after_work.add_reception_pv.title'
      ),
      fields: [
        {
          name: 'pv_file',
          type: InputTypes.DOCUMENT,
          fullWidth: true,
        },
        {
          title: t(
            'mar.worksite_creation.worksite_completion.folder_details.after_work.add_reception_pv.pv_signature_date'
          ),
          name: 'pv_date',
          type: InputTypes.DATE,
          defaultValue: worksiteData.pv_date || undefined,
          fullWidth: true,
        },
      ],
    },
  ];
};

const formPaymentLetter = (
  t: TFunction,
  worksiteData: IWorksiteMar
): IDataMarForm[] => {
  return [
    {
      title: t(
        'mar.worksite_creation.worksite_completion.folder_details.balance.balance_request.title'
      ),
      fields: [
        {
          title: t(
            'mar.worksite_creation.worksite_completion.folder_details.balance.balance_request.add_proof'
          ),
          name: 'request_file',
          type: InputTypes.DOCUMENT,
          fullWidth: true,
        },
        {
          title: t(
            'mar.worksite_creation.worksite_completion.folder_details.balance.balance_request.deposit_date'
          ),
          name: 'payment_request_date',
          type: InputTypes.DATE,
          defaultValue: worksiteData.payment_request_date || undefined,
          fullWidth: true,
        },
      ],
    },
    {
      title: t(
        'mar.worksite_creation.worksite_completion.folder_details.balance.payment_letter.title'
      ),
      fields: [
        {
          title: t(
            'mar.worksite_creation.worksite_completion.folder_details.balance.payment_letter.add_letter'
          ),
          name: 'receipt_file',
          type: InputTypes.DOCUMENT,
          fullWidth: true,
        },
        {
          title: t(
            'mar.worksite_creation.worksite_completion.folder_details.balance.payment_letter.reception_date'
          ),
          name: 'payment_receipt_date',
          type: InputTypes.DATE,
          defaultValue: worksiteData.payment_receipt_date || undefined,
          fullWidth: true,
        },
      ],
    },
  ];
};

const defaultDocumentWithFileType = (fileType: number, worksiteId: number) => ({
  commentary: null,
  created_at: null,
  file: null,
  file_hash: null,
  file_name: null,
  file_url: null,
  id: null,
  is_deletable: null,
  isolate_file: false,
  linked_entity_id: null,
  status: 0,
  uploaded_at: null,
  file_type: fileType,
  relation_type: 'mar_worksite',
  relation_ids: [worksiteId],
});

export {
  formHousingInformation,
  formCustomerInformation,
  formIncomes,
  formTaxHouseholdNumber,
  formTaxHouseholdDeclarant,
  formInformationEnergyAudit,
  formAuditorPerformedBy,
  formAuditDocument,
  formHousingAnalysis,
  formScenariosAndCalculations,
  formQuote,
  formAdministrativeMandate,
  formCheckFieldsMandatory,
  formModalAddDocument,
  formGrantNotification,
  formWorkInProgress,
  formInvoice,
  formAfterWork,
  formPaymentLetter,
  defaultDocumentWithFileType,
};
