import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { Card } from '@components/Card';
import { InputText } from '@components/atomic/inputs/InputText';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ContractCreationContext } from '@models/contractCreation/utils/contractCreationContext';
import {
  BeneficiaryTypes,
  ContractTypes,
} from '@models/contractCreation/utils/enums';
import { InputSelect } from '@components/atomic/inputs/InputSelect';
import { DatePicker } from '@components/atomic/inputs/datepicker/Datepicker';
import { alphanumericRegex } from '@utils/regex';
import { isBefore, parse } from 'date-fns';
import { getRaiAndContractsConvention } from '@models/contracts/apiRequests/newConventionRequests';
import { isDelegatory, isOblige } from '@utils/roles';
import { AuthContext } from '@context/authContext';
import {
  IContractList,
  IVolumeType,
} from '@models/contractCreation/utils/contractCreationTypes';
import { GlobalContext } from '@context/globalContext';
import { formatWord } from '@utils/format';
import {
  generateModelReference,
  generateReference,
} from '@models/contracts/apiRequests/newContractRequests';
import { SaleVolumes } from '@models/contractCreation/components/steps/general/SaleVolumes';
import { WorksiteAddresses } from '@models/contractCreation/components/steps/general/WorksiteAddresses';
import { useParams } from 'react-router-dom';
import { TextWithRadio } from '@components/atomic/inputs/controls/TextWithRadio';
import { Checkbox } from '@components/atomic/inputs/controls/Checkbox';
import { INCENTIVE_TYPE } from '@models/worksiteCreation/utils/enums';
import { filterPricesByIncentive } from '@models/contractCreation/utils/functions';

function StepGeneral() {
  const { t } = useTranslation();
  const methods = useForm();
  const { id } = useParams();

  const contractContext = useContext(ContractCreationContext);

  const {
    referencePerso,
    updateReferencePerso,
    rai,
    updateRai,
    relatedContract,
    updateRelatedContract,
    beneficiaryType,
    updateBeneficiaryType,
    validationDelay,
    updateValidationDelay,
    startDate,
    updateStartDate,
    endDate,
    updateEndDate,
    tunnel,
    contractType,
    updateContractType,
    entityTo,
    updateReferenceOpx,
    referenceOpx,
    volumeMax,
    salePrices,
    penalties,
    origination,
    worksiteAddresses,
    changeStep,
    payloadData,
    raiMention,
    updateRaiMention,
    listObliges,
    updateListObliges,
    incentiveType,
    updateIncentiveType,
    conventionModel,
    operationPrices,
    updateOperationPrices,
    isElectronicSignature,
    updateIsElectronicSignature,
    canManageWorksiteDocuments,
    updateCanManageWorksiteDocuments,
    hasCdpCharterSignatory,
    updateHasCdpCharterSignatory,
  } = contractContext;

  const isConvention = [
    ContractTypes.TRIPARTITE,
    ContractTypes.CONVENTION,
  ].includes(contractType);

  const { user } = useContext(AuthContext);
  const { globalEnum, userView } = useContext(GlobalContext);

  const [listContracts, setListContracts] = useState<IContractList[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTextareaValid, setIsTextareaValid] = useState<boolean>(false);

  const areDatesValid = () => {
    if (startDate === '' || endDate === '') return false;

    const start_date = parse(startDate, 'dd/MM/yyyy', new Date());
    const end_date = parse(endDate, 'dd/MM/yyyy', new Date());

    return isBefore(start_date, end_date);
  };

  const isValid = useMemo(() => {
    const volumeValid = (volume: IVolumeType) => {
      return (
        Number(volume.precaire.replace(',', '.')) > 0 &&
        Number(volume.classic.replace(',', '.')) > 0
      );
    };

    switch (contractType) {
      case ContractTypes.CONVENTION:
        if (userView?.entity_id === rai.value) {
          updateRelatedContract(null);
          return (
            rai.value > 0 &&
            beneficiaryType > 0 &&
            areDatesValid() &&
            isTextareaValid
          );
        }

        return (
          relatedContract &&
          rai.value > 0 &&
          beneficiaryType > 0 &&
          areDatesValid() &&
          isTextareaValid
        );
      case ContractTypes.MANDAT:
      case ContractTypes.MANDAT_NON_DEPOSANT:
        return contractType > 0 && areDatesValid() && isTextareaValid;
      case ContractTypes.VENTE:
      case ContractTypes.DELEGATION:
        return (
          volumeValid(volumeMax) &&
          volumeValid(salePrices) &&
          volumeValid(penalties) &&
          origination > 0 &&
          areDatesValid() &&
          isTextareaValid
        );
      case ContractTypes.TRIPARTITE:
        return (
          rai.value > 0 &&
          beneficiaryType > 0 &&
          areDatesValid() &&
          worksiteAddresses.length > 0 &&
          !worksiteAddresses.some(
            (wa) => wa.address === '' || wa.postal_code === '' || wa.city === ''
          ) &&
          isTextareaValid
        );
      default:
        return false;
    }
  }, [
    tunnel,
    relatedContract,
    contractType,
    rai,
    beneficiaryType,
    areDatesValid(),
    volumeMax,
    salePrices,
    penalties,
    origination,
    worksiteAddresses,
    userView,
    isTextareaValid,
  ]);

  const onClickNextStep = async () => {
    changeStep('next');
  };

  const listBeneficiary = useMemo(() => {
    return Object.entries(globalEnum.beneficiaries_type)
      .map((elt) => ({
        id: Number(elt[0]),
        value: String(elt[1]),
      }))
      .filter((b) =>
        contractType === ContractTypes.TRIPARTITE
          ? b.id !== BeneficiaryTypes.MIXED
          : b.id
      );
  }, [globalEnum]);

  const listIncentive = Object.entries(globalEnum.incentive_type);

  const getData = async (isCopy = false) => {
    setIsLoading(true);

    if (rai.value === 0 || isCopy) {
      const res = await getRaiAndContractsConvention();
      if (res) {
        if (isOblige(user) || isDelegatory(user)) {
          updateRai({ value: Number(res.id), label: String(res.company_name) });
        } else if (res.data) {
          if (res.data.length === 1) {
            updateRai({
              value: Number(res.data[0].id),
              label: String(res.data[0].company_name),
            });
            setListContracts(res.data[0]?.rai_related_contracts || []);
          }
          updateListObliges(res.data);
        }
      }
    }

    if (referenceOpx === '') {
      const res = conventionModel
        ? await generateModelReference()
        : await generateReference(
            entityTo.id,
            isConvention ? 'conventions' : 'contracts'
          );
      updateReferenceOpx(res);
    }

    setIsLoading(false);
  };

  const formatContractDisplay = (contract: IContractList) => {
    return contract.internal_reference
      ? `${contract.reference} - ${contract.internal_reference}`
      : contract.reference;
  };

  const readOnlyBeneficiaryType = contractContext?.operationPrices.length > 0;

  const contractTypeLabel = useMemo(() => {
    return Object.entries(globalEnum.contract_type).find(
      (elt) => Number(elt[0]) === contractType
    )?.[1];
  }, [contractType]);

  const showIncentiveChoices = useMemo(() => {
    if (
      [ContractTypes.VENTE, ContractTypes.DELEGATION].includes(contractType)
    ) {
      return false;
    }
    if (contractType === ContractTypes.MANDAT) return true;

    return (
      relatedContract?.incentive_type === INCENTIVE_TYPE.MIXED ||
      !relatedContract
    );
  }, [relatedContract, contractType]);

  useEffect(() => {
    getData();
  }, [rai]);

  useEffect(() => {
    if (Number(id) > 0) getData(true);
  }, [id]);

  useEffect(() => {
    setIsTextareaValid(raiMention !== '' || listContracts.length > 0);
  }, [raiMention, listContracts]);

  useEffect(() => {
    const selectedRai = listObliges.find((o) => o.id === payloadData?.rai_id);
    if (selectedRai) {
      updateRai({ value: selectedRai?.id, label: selectedRai?.company_name });
    }
  }, [payloadData, listObliges]);

  useEffect(() => {
    const selectedContract = listContracts.find(
      (c) => c.id === payloadData?.contract_id
    );

    if (selectedContract) {
      updateRelatedContract({
        id: payloadData?.contract_id || 0,
        label: selectedContract.reference,
        contract_type: selectedContract.contract_type,
        incentive_type: selectedContract.incentive_type,
      });
    }
  }, [payloadData, listContracts]);

  return (
    <Card
      title={t('partners.general_infos')}
      actionButtons={
        <ButtonOpx
          type="primary"
          label={`${t('buttons.next')}`}
          isSubmitBtn
          onClick={onClickNextStep}
          addClass="infosGeneral"
          disabled={!isValid || isLoading}
          dataTestId="button_next"
        />
      }
    >
      <FormProvider {...methods}>
        <form className="flex flex-col gap-3">
          {[
            ContractTypes.VENTE,
            ContractTypes.DELEGATION,
            ContractTypes.TRIPARTITE,
          ].includes(contractType) && (
            <p className="mb-[1rem] font-medium">
              {t('contract.general_infos_contract')}
            </p>
          )}
          <div className="flex flex-wrap">
            <div className="flex gap-3 w-full">
              <InputText
                id="referenceOpx"
                name="referenceOpx"
                value={referenceOpx}
                disabled
                label={String(t('forms.reference.placeholder'))}
                placeholder=""
                dataTestId="reference_opx"
              />
              <InputText
                id="internal_reference"
                name="internal_reference"
                placeholder={t('global.text_placeholder')}
                value={referencePerso}
                onChange={(e) => updateReferencePerso(String(e))}
                label={String(t('forms.internal_reference.placeholder'))}
                valid={
                  alphanumericRegex.test(referencePerso) &&
                  referencePerso !== ''
                }
                error={!alphanumericRegex.test(referencePerso)}
                dataTestId="internal_reference"
              />
            </div>
          </div>
          {isConvention && (
            <>
              <div className="flex gap-3">
                <InputSelect
                  dataArrayString={listObliges.map((elt) => elt.company_name)}
                  disabled={
                    (userView?.entity_id === rai.value &&
                      listObliges.length < 2) ||
                    readOnlyBeneficiaryType
                  }
                  valueInput={rai.label}
                  defaultSelected={rai.label}
                  onChangeValue={(e) => {
                    if (
                      !isOblige(user) &&
                      !isDelegatory(user) &&
                      listObliges.length > 0
                    ) {
                      const oblige = listObliges.find(
                        (l) => l.company_name === e
                      );

                      updateRai({
                        value: oblige?.id || 0,
                        label: oblige?.company_name || '',
                      });
                      setListContracts(oblige?.rai_related_contracts || []);
                    }
                  }}
                  placeholder={t('global.choose')}
                  label={`${t('partners.obliged.name')}`}
                  required
                  addClass="w-full"
                  dataTestIdSelect="select_oblige"
                  dataTestIdOptions="oblige"
                />
                {userView?.entity_id !== rai.value && (
                  <InputSelect
                    dataArrayString={listContracts.map((contract) =>
                      formatContractDisplay(contract)
                    )}
                    valueInput={relatedContract?.label}
                    defaultSelected={relatedContract?.label}
                    onChangeValue={(e) => {
                      const selected = listContracts.find((elt) =>
                        elt.internal_reference
                          ? `${elt.reference} - ${elt.internal_reference}` ===
                            String(e)
                          : elt.reference === String(e)
                      );
                      if (selected) {
                        updateRelatedContract({
                          id: Number(selected.id),
                          label: String(e),
                          contract_type: selected.contract_type,
                          incentive_type: selected.incentive_type,
                        });
                      }
                    }}
                    placeholder={t('global.choose')}
                    label={String(t('forms.associated_contract.placeholder'))}
                    required
                    addClass="w-full"
                    dataTestIdSelect="select_contract"
                    dataTestIdOptions="contract"
                    disabled={readOnlyBeneficiaryType}
                  />
                )}
              </div>
              {(isOblige(user) || isDelegatory(user)) && (
                <div className="w-full flex gap-3 mt-[1.0rem] mb-[1.0rem] text-[0.875rem]">
                  <div> {t('contract.cdp_charter_signed')}</div>{' '}
                  <Checkbox
                    label=""
                    checked={hasCdpCharterSignatory}
                    onCheck={() =>
                      updateHasCdpCharterSignatory((prevState) => !prevState)
                    }
                    width="1rem"
                  />
                </div>
              )}
            </>
          )}
          {[ContractTypes.MANDAT_NON_DEPOSANT, ContractTypes.MANDAT].includes(
            contractType
          ) && (
            <>
              <InputSelect
                placeholder={t('global.choose')}
                addClass="w-full"
                label={`${t('contract.contract_type')}`}
                required
                dataArrayString={Object.values(globalEnum.contract_type).filter(
                  (elt) => formatWord(elt).includes('mandat')
                )}
                onChangeValue={(e) => {
                  const selected = Object.entries(
                    globalEnum.contract_type
                  ).find((elt) => elt[1] === String(e));
                  if (selected) updateContractType(Number(selected[0]));
                }}
                valueInput={contractTypeLabel}
                defaultSelected={contractTypeLabel}
                dataTestIdSelect="select_contract_type"
                dataTestIdOptions="contract_type"
              />
              {(isOblige(user) || isDelegatory(user)) && (
                <div className="w-full flex gap-5 mt-[1.0rem] mb-[1.0rem] text-[0.875rem]">
                  <div className="w-full flex gap-3 mt-[1.0rem] mb-[1.0rem] text-[0.875rem]">
                    <div> {t('contract.cdp_charter_signed')}</div>{' '}
                    <Checkbox
                      label=""
                      checked={hasCdpCharterSignatory}
                      onCheck={() =>
                        updateHasCdpCharterSignatory((prevState) => !prevState)
                      }
                      width="1rem"
                    />
                  </div>
                  <div className="w-full flex gap-3 mt-[1.0rem] mb-[1.0rem] text-[0.875rem]">
                    <div> {t('contract.can_manage_worksite_documents')}</div>
                    <Checkbox
                      label=""
                      checked={canManageWorksiteDocuments}
                      onCheck={() =>
                        updateCanManageWorksiteDocuments(
                          (prevState) => !prevState
                        )
                      }
                      width="1rem"
                    />
                  </div>
                </div>
              )}
            </>
          )}
          {isConvention && (
            <div className="flex gap-3 w-full">
              {contractType === ContractTypes.CONVENTION && (
                <InputText
                  id="delay"
                  name="delay"
                  placeholder={t('global.number_of_days')}
                  label={`${t('contract.validation_delay')}`}
                  value={validationDelay > 0 ? String(validationDelay) : ''}
                  onChange={(e) => updateValidationDelay(Number(e))}
                  typeNumber
                />
              )}

              <InputSelect
                dataArrayString={listBeneficiary.map((elt) => elt.value)}
                valueInput={globalEnum.beneficiaries_type[beneficiaryType]}
                defaultSelected={globalEnum.beneficiaries_type[beneficiaryType]}
                onChangeValue={(e) => {
                  const selected = listBeneficiary.find(
                    (l) => l.value === String(e)
                  );
                  updateBeneficiaryType(selected?.id || 0);
                }}
                placeholder={t('global.choose')}
                label={String(t('forms.beneficiaries_type.placeholder'))}
                required
                addClass="w-full"
                dataTestIdSelect="select_beneficiary_type"
                dataTestIdOptions="beneficiary_type"
                disabled={readOnlyBeneficiaryType}
              />
            </div>
          )}
          {[ContractTypes.CONVENTION].includes(contractType) && (
            <>
              <p className="text-textGrey text-[0.75rem]">
                {t('buttons.signature')}
              </p>
              <div className="flex flex-row gap-3 w-full">
                <TextWithRadio
                  label={t('global.no')}
                  value="false"
                  setSelectedValue={() => updateIsElectronicSignature(false)}
                  selectValue={isElectronicSignature.toString()}
                  isChecked={isElectronicSignature.toString() === 'false'}
                />
                <TextWithRadio
                  label={t('global.yes')}
                  value="true"
                  setSelectedValue={() => updateIsElectronicSignature(true)}
                  selectValue={isElectronicSignature.toString()}
                  isChecked={isElectronicSignature.toString() === 'true'}
                />
              </div>
            </>
          )}
          {showIncentiveChoices && (
            <InputSelect
              placeholder=""
              dataArrayString={listIncentive.map((elt) => elt[1])}
              valueInput={globalEnum.incentive_type[incentiveType]}
              onSelect={(value) => {
                const selected = listIncentive.find(
                  ([, val]) => val === value
                )?.[0];
                filterPricesByIncentive(
                  Number(selected),
                  operationPrices,
                  updateOperationPrices
                );

                if (selected) updateIncentiveType(Number(selected));
              }}
              label={
                t(
                  'worksite_creation.form_modal_before_simulation.title_incentive_type'
                ) || ''
              }
              required
            />
          )}
          {[ContractTypes.VENTE, ContractTypes.DELEGATION].includes(
            contractType
          ) && (
            <div className="w-full flex gap-3 mt-[1.0rem] mb-[1.0rem] text-[0.875rem]">
              <div> {t('contract.cdp_charter_signed')}</div>{' '}
              <Checkbox
                label=""
                checked={hasCdpCharterSignatory}
                onCheck={() =>
                  updateHasCdpCharterSignatory((prevState) => !prevState)
                }
                width="1rem"
              />
            </div>
          )}
          <div className="flex gap-3 w-full">
            <DatePicker
              required
              onChangeDate={(e) => updateStartDate(e)}
              label={`${t('forms.start_date.placeholder')}`}
              defaultDate={startDate}
              addClass="w-full"
              error={startDate !== '' && endDate !== '' && !areDatesValid()}
              maxDate={endDate}
              dataTestId="start_date"
            />
            <DatePicker
              required
              onChangeDate={(e) => updateEndDate(e)}
              label={`${t('forms.end_date.placeholder')}`}
              defaultDate={endDate}
              addClass="w-full"
              error={startDate !== '' && endDate !== '' && !areDatesValid()}
              dataTestId="end_date"
              minDate={startDate}
            />
          </div>
          {!listContracts.length && (
            <div className="flex gap-3 w-full">
              <InputText
                id="rai_mention"
                name="rai_mention"
                defaultValue={raiMention}
                type="textarea"
                value={raiMention}
                valueInitialInput={raiMention}
                label={String(t('contract.rai_mention.title'))}
                placeholder={t('contract.rai_mention.placeholder')}
                onChange={(e) => {
                  updateRaiMention(String(e));
                }}
                dataTestId="rai_mention"
                required
              />
            </div>
          )}
          {[ContractTypes.VENTE, ContractTypes.DELEGATION].includes(
            contractType
          ) && <SaleVolumes />}
          {contractType === ContractTypes.TRIPARTITE && <WorksiteAddresses />}
        </form>
      </FormProvider>
    </Card>
  );
}

export { StepGeneral };
