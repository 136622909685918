/* eslint-disable react/no-unstable-nested-components */
import { useContext, useEffect, useMemo, useState } from 'react';
import { IColumn, IRow, ListTable } from '@components/ListTable';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { IUserType } from '@models/auth/utils/types';
import { ListPagination } from '@components/atomic/pagination/ListPagination';
import { getUsersList } from '@models/admin/users/apiRequests/usersRequests';
import { HeaderContext } from '@context/headerContext';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '@context/globalContext';
import { ISortAndFilterType } from '@components/sortAndFilter/utils/sortAndFilterTypes';
import { Tag } from '@components/atomic/Tag';
import { blueOpx, green, orange, red } from '@assets/color';
import { EntityStatusEnum } from '@models/worksiteCreation/utils/enums';
import { ADMIN_ROUTES } from '@utils/routesUrls';
import { ResultsPerPageButton } from '@components/atomic/pagination/ResultsPerPageButton';

interface UsersListProps {
  sortAndFilterData: ISortAndFilterType;
  searchKeyword?: string;
}

function UsersList({
  sortAndFilterData,
  searchKeyword,
}: UsersListProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateTitleHeader, refreshHeader, updateDisplayBackButtonHeader } =
    useContext(HeaderContext);

  const { globalEnum } = useContext(GlobalContext);

  const [data, setData] = useState<IUserType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [resultsPerPage, setResultsPerPage] = useState<number>();
  const [paginationData, setPaginationData] = useState<
    Record<string, string | number | null> | undefined
  >();

  const [pagesDatas, setPagesDatas] = useState<{ data: any; meta: any }[]>([]);

  const columnHelper = createColumnHelper<IUserType>();

  const fetchData = async (page: number, perPage?: number) => {
    setLoading(true);
    try {
      const resList = await getUsersList({
        page,
        perPage: perPage || 5,
        sortAndFilterData,
        searchKeyword,
      });
      if (resList) {
        setData(resList.data);
        setPaginationData(resList.meta);
        if (resList.meta.current_page > pagesDatas.length) {
          setPagesDatas((prev) => [
            ...prev,
            { data: resList.data, meta: resList.meta },
          ]);
        }
      }
    } catch (error) {
      console.error('Error fetching user actions history:', error);
    }
    setLoading(false);
  };

  const pageAlreadyLoad = (page: number) => {
    setData(pagesDatas[page - 1].data);
    setPaginationData(pagesDatas[page - 1].meta);
  };

  useEffect(() => {
    refreshHeader();
    updateTitleHeader(`${t('sidebar.users')}`);
    fetchData(1, resultsPerPage);
  }, [sortAndFilterData, resultsPerPage]);

  useEffect(() => {
    fetchData(1, resultsPerPage);
  }, [searchKeyword]);

  const colorTag = (status: number) => {
    switch (status) {
      case EntityStatusEnum.GHOST:
        return orange;
      case EntityStatusEnum.ACTIVE:
        return green;
      default:
        return red;
    }
  };

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('firstname', {
        header: () => t('forms.firstname.placeholder'),
      }),
      columnHelper.accessor('lastname', {
        header: () => t('forms.lastname.placeholder'),
      }),
      columnHelper.accessor('email', {
        header: () => t('forms.email.placeholder'),
      }),
      columnHelper.accessor('identity_status', {
        header: () => t('admin.users.identity_status'),
        cell: (info) => {
          if (info.getValue())
            return (
              <span>{globalEnum.identity_status[Number(info.getValue())]}</span>
            );
          return <span />;
        },
      }),
      columnHelper.accessor('entity_name', {
        header: () => t('admin.users.entity_name'),
      }),
      columnHelper.accessor('entity_type', {
        header: () => t('admin.users.entity_type'),
        cell: (info) => {
          if (info.getValue())
            return (
              <span>{globalEnum.entity_type[Number(info.getValue())]}</span>
            );
          return <span />;
        },
      }),
      columnHelper.accessor('entity_status', {
        header: () => t('admin.users.entity_status'),
        cell: (info) => {
          if (info.getValue())
            return (
              <div className="flex items-center space-x-[1rem] mt-[.375rem]">
                <Tag
                  color={colorTag(Number(info.getValue()))}
                  label={globalEnum.entity_status[Number(info.getValue())]}
                  addClass={globalEnum.entity_status[Number(info.getValue())]}
                />
              </div>
            );
          return <span />;
        },
      }),
    ];
  }, [data]);

  return (
    <>
      <div className="flex justify-between">
        <div className="mt-4">
          {paginationData?.total !== undefined && (
            <div>
              {t('pagination.total_results')} : {paginationData.total}
            </div>
          )}
        </div>
        <div className="mb-4">
          <ResultsPerPageButton
            resultsPerPage={resultsPerPage || 5}
            onChange={(value) => {
              setResultsPerPage(value);
              setPagesDatas([]);
              setCurrentPage(1);
              fetchData(1, value);
            }}
            options={[5, 10, 20, 50, 100, 250]}
            colorPagination={blueOpx}
          />
        </div>
      </div>
      <div>
        <ListTable
          data={data}
          columns={columns as IColumn[]}
          loading={loading}
          callBackOnRowClick={(e: IRow) => {
            const link = ADMIN_ROUTES.ADMIN_USERS;

            const rowData = e.original as IUserType;
            navigate(`${link}/${rowData.id}`);
            updateDisplayBackButtonHeader(true);
          }}
          rowsPerPage={resultsPerPage}
        />
        {paginationData && (
          <ListPagination
            getData={(page) => {
              if (page > pagesDatas.length) {
                fetchData(page, resultsPerPage);
              } else {
                pageAlreadyLoad(page);
              }
            }}
            paginationData={paginationData}
            resultsPerPage={resultsPerPage || 5}
            currentPage={currentPage}
          />
        )}
      </div>
    </>
  );
}

UsersList.defaultProps = {
  searchKeyword: null,
};

export { UsersList };
