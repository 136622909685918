/* eslint-disable no-unused-expressions */
import { GoogleMap, Polygon, Polyline } from '@react-google-maps/api';
import { useState, useEffect } from 'react';
import { blueOpx } from '@assets/color';
import { ColorCube } from '@components/atomic/ColorCube';
import { AddIcon, MinusIcon } from '@assets/images/svgComponents';
import {
  getLatLngWithZipCode,
  getParcelMap,
} from '@models/worksiteCreation/apiRequests/worksiteCreationRequests';
import { InputText } from '@components/atomic/inputs/InputText';
import { useTranslation } from 'react-i18next';
import { LoaderSkeleton } from '@components/loaders/LoaderSkeleton';
import { TextError } from '@components/TextError';
import { OperationTypeEnum } from '@utils/enums';
import { IWorksiteAddress } from '@models/worksiteCreation/utils/types/worksitesType';
import { useFormContext } from 'react-hook-form';

interface ICadastreLocationProps {
  worksiteAddress: IWorksiteAddress;
  updateWorksiteAddressFromKeyValue: (
    updates: Partial<IWorksiteAddress>
  ) => void;
  worksiteOperationType?: OperationTypeEnum;
  readOnly?: boolean;
}

function CadastreLocation({
  worksiteOperationType,
  worksiteAddress,
  updateWorksiteAddressFromKeyValue,
  readOnly,
}: ICadastreLocationProps) {
  const { t } = useTranslation();
  const { setValue } = useFormContext();

  const {
    latitude,
    longitude,
    parcelNumber,
    parcelSection,
    parcelPrefix,
    zipCode,
  } = worksiteAddress;

  const initialLat = Number(latitude !== 0 ? latitude : 46.227638);
  const initialLng = Number(longitude !== 0 ? longitude : 2.213749);
  const [latLngMarker, setLatLngMarker] = useState({
    lat: initialLat,
    lng: initialLng,
  });
  const [coordinates, setCoordinates] = useState([
    {
      lat: initialLat,
      lng: initialLng,
    },
  ]);
  const [zoom, setZoom] = useState(15);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrrorIgn, setIsErrorIgn] = useState('');
  const [isErrorParcel, setIsErrorParcel] = useState(false);

  const getParcelAndSetWorksiteAddress = async ({
    lat,
    lng,
  }: {
    lat: number;
    lng: number;
  }) => {
    const parcelDatas = await getParcelMap(
      t,
      { lat, lng },
      setCoordinates,
      setIsLoading,
      setIsErrorParcel,
      setIsErrorIgn
    );

    if (parcelDatas) {
      updateWorksiteAddressFromKeyValue({
        latitude: lat,
        longitude: lng,
        parcelNumber: parcelDatas.parcelNumber,
        parcelSection: parcelDatas.parcelSection,
        parcelPrefix: parcelDatas.parcelPrefix,
      });
      setValue('section', parcelDatas.parcelSection);
      setValue('number_parcel', parcelDatas.parcelNumber);
      setValue('prefix_parcel', parcelDatas.parcelPrefix);
    }
  };

  const moveMarker = async (event: any) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setIsLoading(true);
    setIsErrorIgn('');

    await getParcelAndSetWorksiteAddress({ lat, lng });

    setLatLngMarker({ lat, lng });
  };

  const lineSymbol = {
    path: 'M 0,-1 0,1',
    fillOpacity: 1,
    scale: 2,
    strokeOpacity: 1,
    strokeColor: blueOpx,
  };

  const lineOptions = {
    strokeColor: 'black',
    strokeOpacity: 0,
    icons: [
      {
        icon: lineSymbol,
        offset: '0',
        repeat: '.875rem',
      },
    ],
    path: coordinates.map((ll) => {
      return { lat: ll.lng, lng: ll.lat };
    }),
  };

  useEffect(() => {
    if (longitude !== 0 && latitude !== 0) {
      setLatLngMarker({ lat: latitude, lng: longitude });
      getParcelAndSetWorksiteAddress({ lat: latitude, lng: longitude });
    } else if (zipCode !== '') {
      getLatLngWithZipCode(zipCode, setLatLngMarker);
    }
  }, [zipCode, longitude, latitude]);

  return (
    <div>
      <p
        className={` ${
          worksiteOperationType === OperationTypeEnum.GLOBAL_RENOVATION
            ? 'text-[1.25rem] pt-[1.5rem]'
            : ''
        }  font-medium`}
      >
        {t('worksite_creation.create_worksite.cadastral_reference.title')}
      </p>
      <p className="text-[.875rem] mt-2">
        {t('worksite_creation.create_worksite.cadastral_reference.description')}
      </p>

      <div className="flex space-x-[.5rem] mt-[1.5rem]">
        <div
          style={{
            height: '25rem',
            width: '100%',
          }}
          className="overflow-hidden mb-[1rem] m-auto relative rounded-default max-h-[15rem]"
        >
          <div
            style={{ zIndex: 9999 }}
            className="absolute right-2 top-2 flex flex-col space-y-[.625rem] items-center rounded-[6px] p-1 bg-backgroundBody shadow-sm"
          >
            <ColorCube
              size="1.5rem"
              numberOrIcon={<AddIcon />}
              color={blueOpx}
              opacity
              onClick={() => setZoom(zoom + 1)}
            />
            <ColorCube
              size="1.5rem"
              numberOrIcon={<MinusIcon />}
              color={blueOpx}
              opacity
              onClick={() => setZoom(zoom - 1)}
            />
          </div>
          <GoogleMap
            zoom={zoom}
            center={latLngMarker}
            mapContainerStyle={{
              height: '100%',
              width: '100%',
            }}
            onClick={(event) => moveMarker(event)}
          >
            <Polygon
              path={coordinates.map((ll) => {
                return { lat: ll.lng, lng: ll.lat };
              })}
              key={1}
              options={{
                fillColor: blueOpx,
                strokeColor: 'transparent',
                fillOpacity: 0.2,
                strokeOpacity: 1,
                strokeWeight: 3,
              }}
            />
            <Polyline
              path={coordinates.map((ll) => {
                return { lat: ll.lng, lng: ll.lat };
              })}
              options={lineOptions}
            />
          </GoogleMap>
        </div>

        <div className="w-full flex flex-col space-y-2">
          <InputText
            dataTestId="input_parcel_section"
            placeholder={t(
              'worksite_creation.create_worksite.cadastral_reference.section'
            )}
            id="section"
            name="section"
            value={parcelSection}
            onChange={(e) => {
              setIsErrorIgn('');
              updateWorksiteAddressFromKeyValue({
                parcelSection: typeof e === 'string' ? e : '',
              });
            }}
            error={parcelSection.length > 2}
            textError={`${t(
              'worksite_creation.create_worksite.cadastral_reference.error_section'
            )}`}
            readOnly={readOnly}
            addClassToInput={readOnly ? 'bg-backgroundBody' : ''}
            required
          />
          <InputText
            dataTestId="input_parcel_number"
            typeNumber
            placeholder={t(
              'worksite_creation.create_worksite.cadastral_reference.number'
            )}
            id="number_parcel"
            name="number_parcel"
            value={parcelNumber}
            onChange={(e) => {
              setIsErrorIgn('');
              updateWorksiteAddressFromKeyValue({
                parcelNumber: typeof e === 'string' ? e : '',
              });
            }}
            maxLength={4}
            valid={parcelNumber.length === 4}
            error={parcelNumber.length > 4}
            textError={`${t(
              'worksite_creation.create_worksite.cadastral_reference.error_number'
            )}`}
            readOnly={readOnly}
            addClassToInput={readOnly ? 'bg-backgroundBody' : ''}
            required
          />
          <InputText
            dataTestId="input_parcel_prefix"
            placeholder={t(
              'worksite_creation.create_worksite.cadastral_reference.prefix'
            )}
            id="prefix_parcel"
            name="prefix_parcel"
            value={parcelPrefix}
            onChange={(e) => {
              setIsErrorIgn('');
              updateWorksiteAddressFromKeyValue({
                parcelPrefix: typeof e === 'string' ? e : '',
              });
            }}
            valid={parcelPrefix.length === 3}
            error={parcelPrefix.length > 3}
            textError={`${t(
              'worksite_creation.create_worksite.cadastral_reference.error_prefix'
            )}`}
            maxLength={3}
            readOnly={readOnly}
            addClassToInput={readOnly ? 'bg-backgroundBody' : ''}
            required
          />
          {isLoading && (
            <div className=" w-full">
              <LoaderSkeleton height=".4rem" backgroundColor={blueOpx} />
            </div>
          )}
        </div>
      </div>
      {isErrorParcel && (
        <TextError
          errorMessage={t(
            'worksite_creation.create_worksite.cadastral_reference.error'
          )}
        />
      )}

      {isErrrorIgn !== '' && <TextError errorMessage={isErrrorIgn} />}
    </div>
  );
}

export { CadastreLocation };

CadastreLocation.defaultProps = {
  worksiteOperationType: undefined,
  readOnly: false,
};
