import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { GlobalContext } from '@context/globalContext';
import {
  checkLinkedFileSignature,
  deleteLinkedFile,
  deleteRegistrationLinkedFile,
  sendLinkedFile,
  sendRegistrationLinkedFiles,
  // validateOrRejectDocument,
} from '@apiRequests/globalRequests';
import { DocumentCardInList } from '@components/documents/DocumentCardInList';
import { IWorksite } from '@models/worksiteCreation/utils/types/worksitesType';
import {
  IWorksiteDetails,
  IWorksiteInfosOp,
  IWorksiteOperation,
} from '@models/worksites/utils/worksitesTypes';
import { initialOtherDocument } from '@utils/initialState';
import { addFiscalNameToTitle } from '@utils/functions';
import { AuthContext } from '@context/authContext';
import { fileTypeEnum } from '@utils/enums';
import { ModalDocumentFailSignature } from '@components/documents/ModalDocumentFailSignature';
import { DOCUMENT_TYPES } from '@utils/utils';
import { useTranslation } from 'react-i18next';
import {
  IDocumentSignModalData,
  ILinkedFile,
  ILinkedFilesGrouped,
} from '../../types/globalTypes';

interface IListDocumentCardProps {
  documents?: ILinkedFile[];
  updateParentDocuments?: () => Promise<void>;
  // worksiteFiles?: boolean;
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
  // arrayFiscalNames?: string[];
  worksiteDatas?: IWorksite | IWorksiteDetails;
  typesFilter?: number[];
  forceImportMode?: boolean;
  updateOneDocument?: (doc: ILinkedFile) => void;
  deleteOneDocument?: (doc: ILinkedFile) => void;
  userId?: number;
  entityTypeId?: number;
  readOnly?: boolean;
  haveDocumentViewer?: boolean;
  customFieldFiles?: any[];
  showAnalysis?: boolean;
}

function ListDocumentsCard({
  documents,
  updateParentDocuments,
  setIsLoading,
  worksiteDatas,
  typesFilter,
  forceImportMode,
  // les 3 props ci-dessous sont requises si ça ne concerne pas de worksite (ex: compte ghost)
  updateOneDocument,
  deleteOneDocument,
  userId,
  entityTypeId,
  readOnly,
  haveDocumentViewer,
  customFieldFiles,
  showAnalysis,
}: IListDocumentCardProps) {
  const { t } = useTranslation();
  const { updateDocumentActive, globalEnum } = useContext(GlobalContext);
  const { user } = useContext(AuthContext);

  const [closeDropdown, setCloseDropdown] = useState<boolean>(false);

  const [modal, setModal] = useState<boolean>(false);
  const [modalPromise, setModalPromise] = useState<{
    resolve: (value: boolean) => void;
  } | null>(null);
  const [signModalData, setSignModalData] =
    useState<IDocumentSignModalData | null>(null);

  const [groupsSorted, setGroupsSorted] = useState<ILinkedFilesGrouped[]>();

  const onSelect = (document: ILinkedFile) => {
    let title = globalEnum.linked_file_type[document.file_type];
    if (
      worksiteDatas &&
      document.relation_type === 'fiscal_declaration' &&
      worksiteDatas.fiscalDeclarations
    ) {
      title = addFiscalNameToTitle(document, globalEnum, worksiteDatas);
    }
    if (document.file_url !== null) {
      updateDocumentActive({
        list: title,
        document,
        listAsTitle: true,
      });
    }
  };

  const onUpload = async (
    file: File,
    document: ILinkedFile,
    customfield_id: number | null
  ) => {
    if (setIsLoading) {
      setIsLoading(true);
    }
    try {
      let newDocument: any;
      if (userId && entityTypeId) {
        let documentFileType = document.file_type;
        switch (documentFileType) {
          case 1701:
          case 1702:
          case 1703:
            documentFileType = 17;
            break;
          default:
            break;
        }
        newDocument = await sendRegistrationLinkedFiles(
          file,
          document.relation_ids || [0],
          document.relation_type || '',
          documentFileType,
          2,
          document.commentary || '',
          entityTypeId,
          document.mandatory || false,
          document.id || undefined,
          document.created_by || undefined,
          updateParentDocuments,
          customfield_id || 0
        );
      } else {
        if (document.file_type === fileTypeEnum.PHOTO_HORODATEE) {
          const signed = await checkLinkedFileSignature(
            file,
            document.relation_ids || [0],
            document.relation_type || '',
            document.file_type
          );
          if (signed.data.success === false) {
            // Ouvrir la modal et attendre la réponse de l'utilisateur
            const userConfirmedPromise = new Promise<boolean>((resolve) => {
              setModalPromise({ resolve });
            });
            setSignModalData({ file, document, customfield_id });
            setModal(true);
            if (setIsLoading) {
              setIsLoading(false);
            }
            const userConfirmed = await userConfirmedPromise;
            if (!userConfirmed) {
              // L'utilisateur a annulé
              return;
            }
          }
        }
        newDocument = await sendLinkedFile(
          file,
          document.relation_ids || [0],
          document.relation_type || '',
          document.file_type,
          2,
          document.commentary || '',
          document.linked_entity_id,
          document.mandatory || false,
          document.can_duplicate || false,
          document.id || undefined,
          updateParentDocuments,
          customfield_id || 0
        );
      }

      let title = globalEnum.linked_file_type[document.file_type];

      if (
        worksiteDatas &&
        document.relation_type === 'fiscal_declaration' &&
        worksiteDatas.fiscalDeclarations
      ) {
        title = addFiscalNameToTitle(document, globalEnum, worksiteDatas);
      }

      if (haveDocumentViewer) {
        updateDocumentActive({
          list: title,
          document: newDocument.data,
          listAsTitle: true,
        });
      }

      if (updateOneDocument) {
        updateOneDocument({
          ...newDocument.data,
          file_type: document.file_type,
          order: document.order,
        });
      }

      setCloseDropdown(true);
    } catch (error) {
      toast.error(t('toast.errors.send_file'));
    } finally {
      if (setIsLoading) {
        setIsLoading(false);
      }
    }
  };

  const onDelete = async (document: ILinkedFile) => {
    if (userId && deleteOneDocument && document.ids) {
      const promises = document.ids.map((id: number) => {
        // let documentToDelete = {
        //   ...document,
        //   file_type:
        // };
        return deleteRegistrationLinkedFile(id, document, deleteOneDocument);
      });
      await Promise.all(promises).then(() => {
        return true;
      }); // charge uniquement après toutes les suppressions
    } else if (document.ids) {
      const promises = document.ids.map((id: number) =>
        deleteLinkedFile(id, updateDocumentActive)
      );
      await Promise.all(promises).then(() => {
        if (updateParentDocuments) {
          updateParentDocuments();
        }
      }); // charge uniquement après toutes les suppressions
    } else {
      await deleteLinkedFile(document.id || 0, updateDocumentActive).then(
        () => {
          if (updateParentDocuments) {
            updateParentDocuments();
          }
        }
      );
    }
  };

  const groupedLinkedFiles = useMemo(() => {
    // Initialisation de grouped en tant que tableau
    const grouped: ILinkedFilesGrouped[] = [];
    const documentsArray = documents ?? [];
    documentsArray.forEach((document) => {
      // Si le tableau typesFilter est défini et que le type de fichier actuel n'est pas inclus,
      // on passe à l'itération suivante de la boucle
      if (typesFilter && !typesFilter.includes(document.file_type)) {
        return;
      }

      // Recherche d'un groupe existant pour ce type de fichier
      let group: ILinkedFilesGrouped | undefined;

      if (document.isolate_file) {
        group = undefined; // afficher les itérations du file_type = horodatee indiqué par isolate_file = true
      } else if (document.relation_type !== 'fiscal_declaration') {
        group = grouped.find((g) => g.file_type === document.file_type);
      } else {
        group = grouped.find(
          (g) =>
            g.file_type === document.file_type &&
            g.documents[0].relation_ids?.includes(
              document.relation_ids ? document.relation_ids[0] : 0
            )
        );
      }

      if (!group) {
        let title = globalEnum.linked_file_type[document.file_type];
        if (
          worksiteDatas &&
          document.relation_type === 'fiscal_declaration' &&
          worksiteDatas.fiscalDeclarations
        ) {
          title = addFiscalNameToTitle(document, globalEnum, worksiteDatas);
        }

        const tooltip = document.tooltip || undefined;
        // Si aucun groupe n'existe pour ce type de fichier, créez-en un
        group = {
          title,
          file_type: document.file_type,
          documents: [],
          required: document.mandatory || false, // valeur par défaut false si document.mandatory est undefined
          is_operation_document:
            document.relation_type?.includes('operation') || false, // valeur par défaut false si document.relation_type?.includes('operation') est undefined,
          order: document.order,
          can_duplicate: !!document.can_duplicate,
          tooltip,
        };
        grouped.push(group);
      }

      // Vérifiez si le groupe existe avant de l'utiliser
      if (group) {
        group.documents.push(document);
      }
    });

    // Si le tableau typesFilter est défini et contient le numéro 16,
    // et qu'il n'existe pas déjà un groupe avec file_type = 16,
    // on ajoute un objet de fichier neutre au tableau grouped
    if (
      typesFilter &&
      typesFilter.includes(16) &&
      grouped.findIndex((g) => g.file_type === 16) === -1
    ) {
      grouped.push({
        title: globalEnum.linked_file_type[16],
        file_type: 16,
        documents: [initialOtherDocument(worksiteDatas?.id || 0)],
        required: false,
        is_operation_document: false,
      });
    }

    return grouped.sort((a, b) => {
      if (a.is_operation_document && !b.is_operation_document) {
        return 1; // a vient après b
      }
      if (!a.is_operation_document && b.is_operation_document) {
        return -1; // a vient avant b
      }
      return 0; // aucun changement dans l'ordre
    });
  }, [documents]);

  const handleModalConfirm = async () => {
    if (modalPromise) {
      modalPromise.resolve(true);
      setModalPromise(null);
    }
    if (signModalData) {
      setSignModalData(null);
      setModal(false);
    }
  };

  const handleModalCancel = () => {
    if (modalPromise) {
      modalPromise.resolve(false);
      setModalPromise(null);
    }
    setSignModalData(null);
    setModal(false);
  };

  useEffect(() => {
    const groupedLinkedFilesWithOperations = groupedLinkedFiles.map((group) => {
      if (group.is_operation_document && worksiteDatas) {
        const groupedLinkedFilesOperations:
          | {
              relation_id: number;
              name: string;
            }[] = [];
        group.documents.forEach((doc) => {
          if (doc.relation_ids && doc.relation_ids.length > 0) {
            doc.relation_ids.forEach((relationId) => {
              const operation = (
                worksiteDatas.worksites_operations as IWorksiteOperation[]
              ).find((op) => op.id === relationId)?.operation as
                | IWorksiteInfosOp
                | undefined;
              if (operation) {
                groupedLinkedFilesOperations.push({
                  relation_id: relationId,
                  name: operation.description,
                });
              }
            });
          }
        });
        if (groupedLinkedFilesOperations.length > 0) {
          // Supprimer les doublons en filtrant le tableau
          const uniqueOperations = groupedLinkedFilesOperations.filter(
            (operation, index, self) =>
              index ===
              self.findIndex((op) => op.relation_id === operation.relation_id)
          );

          return { ...group, operations: uniqueOperations };
        }
      }
      return group;
    });

    // Séparation en 3 sous groupes : Obligatoires, Mixes, Optionnels
    const groupsRequired: ILinkedFilesGrouped[] = [];
    const groupsMixed: ILinkedFilesGrouped[] = [];
    const groupsOptionnals: ILinkedFilesGrouped[] = [];
    groupedLinkedFilesWithOperations.forEach((group) => {
      if (group.required && group.file_type !== DOCUMENT_TYPES.PhotoHorodatee) {
        groupsRequired.push(group);
      } else if (group.file_type === DOCUMENT_TYPES.PhotoHorodatee) {
        groupsMixed.push(group);
      } else {
        groupsOptionnals.push(group);
      }
    });
    // Tri des Mixes par ordre ou par obligation
    groupsMixed.sort((a, b) =>
      a.order && b.order
        ? a.order - b.order
        : Number(b.required) - Number(a.required)
    );
    // Tri des Mixes pour afficher ceux qui ont un document upload en premier
    groupsMixed.sort((a, b) => {
      const aHasFileUrl = Boolean(a.documents[0].file_url);
      const bHasFileUrl = Boolean(b.documents[0].file_url);
      if (aHasFileUrl && !bHasFileUrl) return -1;
      if (!aHasFileUrl && bHasFileUrl) return 1;
      return 0;
    });
    const newSortedGroups: ILinkedFilesGrouped[] = [
      ...groupsRequired,
      ...groupsMixed,
      ...groupsOptionnals,
    ];
    setGroupsSorted(newSortedGroups);
  }, [groupedLinkedFiles]);

  const isDrapoAccount = useMemo(
    () => user?.email?.match(/@drapo\.com$/) !== null,
    [user]
  );

  return (
    <div className="flex flex-col space-y-[1rem] w-full">
      {modal && (
        <ModalDocumentFailSignature
          onConfirmClick={handleModalConfirm}
          onCancelClick={handleModalCancel}
          signModalData={signModalData}
        />
      )}
      {groupsSorted &&
        groupsSorted.map((group, index) => (
          <DocumentCardInList
            key={group.file_type}
            groupedDocuments={group} // Passer les documents triés au composant
            onSelect={onSelect}
            onUpload={onUpload}
            onDelete={onDelete}
            updateParentDocuments={updateParentDocuments}
            closeDropdown={closeDropdown}
            setCloseDropdown={setCloseDropdown}
            index={index}
            importMode={forceImportMode || !!typesFilter}
            readOnly={!!readOnly}
            showAnalysis={showAnalysis && isDrapoAccount}
          />
        ))}

      {customFieldFiles?.map((customFieldFile, index) => (
        <DocumentCardInList
          key={`cf${customFieldFile.id}`}
          groupedDocuments={customFieldFile}
          onSelect={onSelect}
          onUpload={onUpload}
          onDelete={onDelete}
          updateParentDocuments={updateParentDocuments}
          closeDropdown={closeDropdown}
          setCloseDropdown={setCloseDropdown}
          index={index}
          importMode={forceImportMode || !!typesFilter}
          readOnly={!!readOnly}
        />
      ))}
    </div>
  );
}

export { ListDocumentsCard };

ListDocumentsCard.defaultProps = {
  documents: [],
  setIsLoading: undefined,
  worksiteDatas: undefined,
  typesFilter: undefined,
  forceImportMode: false,
  updateOneDocument: undefined,
  deleteOneDocument: undefined,
  userId: undefined,
  entityTypeId: undefined,
  updateParentDocuments: undefined,
  readOnly: false,
  haveDocumentViewer: true,
  customFieldFiles: [],
  showAnalysis: false,
};
