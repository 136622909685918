import { useState, useMemo, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { HeaderContext } from '@context/headerContext';
import { GlobalContext } from '@context/globalContext';
import { AuthContext } from '@context/authContext';

import { CardInformations } from '@components/informations/CardInformations';
import { DocumentViewer } from '@components/documents/DocumentViewer';

import { getContacts } from '@models/users/apiRequests/userRequests';

import {
  getInfos,
  handleUpload,
} from '@models/conventions/utils/conventionHelper';
import { getCardInfosGeneralContract } from '@models/contracts/utils/contractHelper';

import { IContractInfosGeneralType } from '@models/contracts/utils/contractTypes';

import { ENTITY_TYPES } from '@utils/roles';
import { initDocumentActive } from '@utils/initialState';

import { red, lightBlue, iconBlue } from '@assets/color';
import { URL_TYPES } from '@models/contracts/utils/contractConstants';
import { convertKiloToMega } from '@utils/functions';
import { WorksiteIcon } from '@assets/images/svgComponents';
import { KpiTab } from '@components/atomic/KpiTab';
import { IKPITab } from '@../../types/globalTypes';
import { InternalReferents } from '@models/contracts/components/InternalReferents';
import { IUserType } from '@models/auth/utils/types';
import { ContractTypes } from '@models/contractCreation/utils/enums';
import { ILinkedFile } from 'types/globalTypes';
import { UploadButton } from '@components/documents/UploadButton';

interface ITabInfosContractsProps {
  contractId: number;
  contractType: string;
  data: IContractInfosGeneralType | undefined;
  refresh: () => void;
  isLoading: boolean;
}

function TabInfosContracts(props: ITabInfosContractsProps) {
  const { contractId, contractType, data, isLoading, refresh } = props;
  const { t } = useTranslation();
  const { roleUser, updateDocumentActive, userView, globalEnum } =
    useContext(GlobalContext);
  const { titleHeader } = useContext(HeaderContext);
  const { user } = useContext(AuthContext);

  const [contacts, setContacts] = useState<IUserType[]>([]);
  const [isLoadingDocumentViewer, setIsLoadingDocumentViewer] =
    useState<boolean>(false);

  const isCreator = useMemo(() => {
    return userView?.entity_id === data?.entity.id;
  }, [userView, data]);

  const getUserContacts = async () => {
    const res = await getContacts();
    setContacts(res);
  };

  useMemo(() => {
    const check = data && data.linkedFiles && data.linkedFiles.length > 0;

    updateDocumentActive(
      check && data.linkedFiles
        ? {
            list: titleHeader,
            document: data.linkedFiles[0],
            listAsTitle: true,
          }
        : { ...initDocumentActive, list: titleHeader, listAsTitle: true }
    );

    return check;
  }, [data, titleHeader]);

  const badge = useMemo(() => {
    if (
      data &&
      [URL_TYPES.MANDANT, URL_TYPES.MANDANT_NON_DEPOSANT].includes(contractType)
    ) {
      const progressStatus = data.progress_status;

      return {
        color: [1, 3].includes(progressStatus) ? red : lightBlue,
        label: globalEnum.contract_progress_status[`${progressStatus}`],
      };
    }
    return undefined;
  }, [data, contractId]);

  // partenaire ou vendeur
  const itemsInfoPartnerOrVendeur = useMemo(() => {
    if (data) {
      return getInfos(
        t,
        data.entity,
        data.contactEntity,
        { typeContact1: 3, typeContactSign: 2 },
        contacts.map((c) => `${c.firstname} ${c.lastname}`),
        undefined
      );
    }
    return [];
  }, [t, data, roleUser, contacts, user, userView]);

  // mandataire ou acheteur
  const itemsInfoPartner = useMemo(() => {
    if (data) {
      return getInfos(
        t,
        isCreator ? data.entityTo : data.entity,
        isCreator ? data.contactEntityTo : data.contactEntity,
        { typeContact1: !isCreator ? 3 : 1, typeContactSign: 2 },
        contacts.map((c) => `${c.firstname} ${c.lastname}`),
        true
      );
    }
    return [];
  }, [t, data, roleUser, contacts, userView]);

  const renderInfosGeneral = () => {
    return (
      <CardInformations
        title={t('convention.general_information')}
        data={{
          status: badge,
          rows: data ? getCardInfosGeneralContract(t, contractType, data) : [],
        }}
        loading={isLoading}
        dataTestId="informations_card"
      />
    );
  };

  const renderInfosPartner = () => {
    const imSeller =
      (data?.contract_type === ContractTypes.VENTE &&
        data.entity.id === userView?.entity_id) ||
      (data?.contract_type === ContractTypes.DELEGATION &&
        data.entityTo.id === userView?.entity_id);

    let title = t('contract.information_representative');

    if (data?.entity.id !== userView?.entity_id) {
      title = `${t('convention.partner_information')} ${
        data?.entity.company_name || ''
      }`;
    }

    if (
      [ContractTypes.VENTE, ContractTypes.DELEGATION].includes(
        Number(data?.contract_type)
      )
    ) {
      title = imSeller
        ? t('contract.buyer_information')
        : t('contract.seller_information');
    }

    if (!data) return <div />;

    return (
      <CardInformations
        data={{
          status: undefined,
          rows: itemsInfoPartner,
        }}
        loading={false}
        title={title}
      />
    );
  };

  const renderInfosReferents = () => {
    const userContacts =
      userView?.entity_id === data?.entity.id
        ? data?.contactEntity
        : data?.contactEntityTo;

    if (data && userContacts) {
      return (
        <InternalReferents
          contacts={userContacts}
          list={contacts}
          contractId={data.id}
          contractType={data.contract_type}
          onRefresh={refresh}
          isCreator={isCreator}
        />
      );
    }

    let title = `${t('convention.partner_information')} ${
      (itemsInfoPartnerOrVendeur[0][0] as any).value
    }`;

    if ([URL_TYPES.SALE, URL_TYPES.DELEGATION].includes(contractType)) {
      title = t('contract.seller_information');
    }

    return (
      <CardInformations
        title={title}
        data={{
          status: undefined,
          rows: itemsInfoPartnerOrVendeur,
        }}
        loading={false}
      />
    );
  };

  const vClassique =
    data && data.convention_worksites ? data.convention_worksites.classique : 0;
  const vPrecaire =
    data && data.convention_worksites ? data.convention_worksites.precaire : 0;

  const vMaxClassique = Number(data?.volume_classique_kwhc || 0);
  const vMaxPrecaire = Number(data?.volume_precaire_kwhc || 0);

  const ratioClassic =
    vMaxClassique > 0 ? Number(vClassique) / vMaxClassique : 0;
  const ratioPrecaire = vMaxPrecaire > 0 ? Number(vPrecaire) / vMaxPrecaire : 0;

  let titleNb = '';
  let dataNb = 0;

  switch (contractType) {
    case URL_TYPES.CONVENTION:
      titleNb = t('convention.tab_info_general.KPITabs.nb_worksites');
      dataNb = data?.convention_worksites.nb_worksites || 0;
      break;
    case URL_TYPES.MANDANT_NON_DEPOSANT:
      titleNb = t('convention.tab_info_general.KPITabs.nb_lots');
      dataNb = data?.nb_lots_done || 0;
      break;
    case URL_TYPES.MANDANT:
      titleNb = t('convention.tab_info_general.KPITabs.nb_depots');
      dataNb = data?.nb_lots_done || 0;
      break;
    case URL_TYPES.SALE:
    case URL_TYPES.DELEGATION:
      titleNb = t('convention.tab_info_general.KPITabs.nb_delivery');
      dataNb = data?.contractsTransfers.length || 0;
      break;
    default:
      break;
  }

  const kpiTabContracts: IKPITab[] = [
    {
      infos: [
        {
          title: titleNb,
          subtitle: String(dataNb),
        },
      ],
      icon: <WorksiteIcon />,
      colorIcon: iconBlue,
      dataTestId: 'nb_transfers_worksites',
    },
    {
      infos: [
        {
          title: t('global.KPITabs.volume_cee_classique'),
          subtitle: `${convertKiloToMega(vClassique)} / ${convertKiloToMega(
            vMaxClassique
          )} MWhc`,
        },
      ],
      colorIcon: '#46BDE2',
      progressBar: true,
      ratios: ratioClassic,
    },
    {
      infos: [
        {
          title: t('global.KPITabs.volume_cee_precaire'),
          subtitle: `${convertKiloToMega(vPrecaire)} / ${convertKiloToMega(
            vMaxPrecaire
          )} MWhc`,
        },
      ],
      colorIcon: '#916BE2',
      progressBar: true,
      ratios: ratioPrecaire,
    },
  ];

  const handleUploadFile = async (file: File, document: ILinkedFile) => {
    await handleUpload(file, document, refresh);
  };

  useEffect(() => {
    getUserContacts();
  }, []);

  if (!data) return null;

  return (
    <div className="w-full flex flex-wrap">
      <div className="w-full md:w-[60%] md:pr-6 grid grid-cols-1 gap-y-6">
        {data && (
          <>
            <KpiTab infos={kpiTabContracts} />
            {renderInfosGeneral()}
            {[URL_TYPES.MANDANT, URL_TYPES.MANDANT_NON_DEPOSANT].includes(
              contractType
            ) && (
              <>
                {renderInfosPartner()}
                {renderInfosReferents()}
              </>
            )}
            {[URL_TYPES.SALE, URL_TYPES.DELEGATION].includes(contractType) && (
              // vue vendeur
              <>
                {renderInfosPartner()}
                {renderInfosReferents()}
              </>
            )}
          </>
        )}
      </div>
      <div className="w-full md:w-[40%]">
        {data && data.linkedFiles && data.linkedFiles.length > 0 ? (
          <div className="flex flex-col gap-5">
            <UploadButton
              fileType={15}
              name={t('contract.document.update')}
              onUpload={handleUploadFile}
              document={{
                id: null,
                relation_ids: [data.id],
                relation_type: 'contract',
                file_type: 15,
                status: 1,
                isolate_file: false,
                file_url: null,
                file_name: null,
                linked_entity_id: null,
                commentary: null,
                file: null,
                created_at: null,
                uploaded_at: null,
                is_deletable: null,
                file_hash: null,
              }}
            />
            <DocumentViewer
              isLoading={isLoading || isLoadingDocumentViewer}
              setIsLoading={setIsLoadingDocumentViewer}
            />
          </div>
        ) : (
          <div className="w-full bg-white border-[1px] py-[1.5rem] px-[2.75rem] border-borderGrey rounded-default overflow-hidden flex flex-col space-y-[1.5rem]">
            {user &&
              user.current_entity_type &&
              [
                ENTITY_TYPES.MANDATAIRE,
                ENTITY_TYPES.OBLIGE,
                ENTITY_TYPES.DELEGATAIRE,
              ].includes(user.current_entity_type) && (
                <UploadButton
                  fileType={15}
                  name={t('contract.document.import')}
                  onUpload={handleUploadFile}
                  document={
                    data.linkedFiles?.[0] ?? {
                      id: null,
                      relation_ids: [data.id],
                      relation_type: 'contract',
                      file_type: 15,
                      status: 1,
                      isolate_file: false,
                      file_url: null,
                      file_name: null,
                      linked_entity_id: null,
                      commentary: null,
                      file: null,
                      created_at: null,
                      uploaded_at: null,
                      is_deletable: null,
                      file_hash: null,
                    }
                  }
                />
              )}
            <p className="text-[1rem] leading-[1.25rem] font-semibold">
              {t('contract.document.no_linked_files')}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export { TabInfosContracts };
