import { axiosClient } from '@api/apiClient';
import {
  urlDeleteMarQuotes,
  urlFetchIncomesMar,
  urlGetCollaborators,
  urlGetInvoiceWorksCertificate,
  urlGetMarCustomers,
  urlGetMarDocuments,
  urlGetMarDocumentsDownload,
  urlGetMarQuotesSelected,
  urlGetMarRecentCustomers,
  urlGetMarWorksiteDataWithId,
  urlGetQuoteWorksCertificate,
  urlLinkedFileAction,
  urlPostEnergyClass,
  urlPostGenerateAdditionalCommitmentsCertificate,
  urlPostLinkedFiles,
  urlPostMandateGeneration,
  urlPostMarCreateWorksite,
  urlPostMarOperationsList,
  urlPostMarPro,
  urlPostMarProsList,
  urlPostMarScenarioPrime,
  urlPostmarValidateAdministrativeMandate,
  urlPostMarValidateAfterWork,
  urlPostMarValidateCeeAnah,
  urlPostMarValidateCustomerInformation,
  urlPostMarValidateDeposit,
  urlPostMarValidateEndOfWork,
  urlPostMarValidateEnergyAudit,
  urlPostMarValidateHousingAnalysis,
  urlPostMarValidateHousingTypology,
  urlPostMarValidateInvoice,
  urlPostMarValidateNotificationOfGrant,
  urlPostMarValidateOneQuote,
  urlPostMarValidateOneScenario,
  urlPostMarValidatePaymentLetter,
  urlPostMarValidateQuotes,
  urlPostMarValidateScenariosAndCalculations,
  urlPostMarValidateWorkInProgress,
  urlPostMarWorksitesList,
  urlPutMarStoreCustomer,
} from '@api/apiUrls';
import { toast } from 'react-toastify';
import {
  IAuditDataSend,
  ICustomer,
  IEndOfWorkDataToSend,
  IHousingAnalysisDataSend,
  IWorksiteMar,
} from '@models/mar/utils/marTypes';
import { Dispatch, SetStateAction } from 'react';
import { IWorksiteAddress } from '@models/worksiteCreation/utils/types/worksitesType';
import { ILinkedFile } from '@globalTypes/globalTypes';
import { ISortAndFilterType } from '@components/sortAndFilter/utils/sortAndFilterTypes';

// RECUPERE LA LISTE DE TOUT LES USAGERS
export const getListAllCustomers = async (
  numberPageListBeneficiaries: number,
  keyword?: string,
  returnMeta?: boolean
) => {
  const dataPost = {
    pagination: {
      page: numberPageListBeneficiaries,
      perPage: 5,
    },
    keyword: keyword || '',
  };
  try {
    const response = await axiosClient.post(urlGetMarCustomers, dataPost);
    return returnMeta ? response.data : response.data.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

// RECUPERE LA LISTE DES USAGERS RECENTS
export const getListRecentCustomers = async () => {
  try {
    const response = await axiosClient.get(urlGetMarRecentCustomers);
    return response.data.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

// CREATION D'UN NOUVEL USAGER
export const createCustomer = async (
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  updateCustomer: Dispatch<SetStateAction<ICustomer | undefined>>,
  dataPost: ICustomer,
  setIsError: Dispatch<SetStateAction<boolean>>,
  setErrorMessages: Dispatch<SetStateAction<string[] | undefined>>
) => {
  setIsLoading(true);

  try {
    const response = await axiosClient.put(urlPutMarStoreCustomer, dataPost);
    updateCustomer(response.data.data);
    setIsLoading(false);
    return response.data.data;
  } catch (error) {
    if (error.response.data.errors) {
      setErrorMessages(error.response.data.errors);
    }
    setIsError(true);
    setIsLoading(false);
    return false;
  }
};

// CREATION DU CHANTIER
export const createWorksiteMar = async (
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  dataPost: {
    customer_id: number;
    contract?: File;
  }
) => {
  setIsLoading(true);
  const formData = new FormData();
  formData.append('customer_id', dataPost.customer_id.toString());
  if (dataPost.contract) {
    formData.append('contract', dataPost.contract);
  }

  try {
    const response = await axiosClient.post(
      urlPostMarCreateWorksite,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
    setIsLoading(false);
    return response.data.data;
  } catch (error) {
    setIsLoading(false);
    return false;
  }
};

export const getWorksiteDataWithId = async (id: number) => {
  try {
    const response = await axiosClient.get(urlGetMarWorksiteDataWithId(id));
    return response.data.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const validateHousingTypology = async (
  id: number,
  data: {
    address: {
      address_type: number;
      address: string;
      postal_code: string;
      city: string;
      country: string;
      longitude: number;
      latitude: number;
    };
    housing_type: number;
    cadastral_parcel: string;
    age: number;
    surface: number | null;
    tax_reference: string;
  }
) => {
  try {
    const response = await axiosClient.post(
      urlPostMarValidateHousingTypology(id),
      data
    );
    return response.data.data as IWorksiteMar;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const fetchIncomesMar = async (
  persons: string,
  worksiteAddress: IWorksiteAddress
) => {
  const dataPost = {
    persons,
    works: {
      worksStreet: worksiteAddress.streetName,
      worksStreetNumber: worksiteAddress.numberStreet,
      worksZip: worksiteAddress.zipCode,
      worksCity: worksiteAddress.city,
    },
  };
  try {
    const response = await axiosClient.post(urlFetchIncomesMar, dataPost);
    return response.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const validateCustomerInformation = async (
  id: number,
  data: {
    occupation_type: number;
    persons: number;
    precarity_type: number;
    ptz: boolean;
    opah: boolean;
    tax_households: {
      firstname: string;
      lastname: string;
    }[];
  }
) => {
  try {
    const response = await axiosClient.post(
      urlPostMarValidateCustomerInformation(id),
      data
    );
    return response.data.data as IWorksiteMar;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const fetchCollaborators = async () => {
  try {
    const response = await axiosClient.get(urlGetCollaborators);
    return response.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const validateEnergyAudit = async (
  id: number,
  dataToSend: IAuditDataSend
) => {
  try {
    const formData = new FormData();

    formData.append('audit_number', dataToSend.audit_number);
    formData.append('visit_date', dataToSend.visit_date);
    formData.append('report_date', dataToSend.report_date);
    formData.append('firstname', dataToSend.firstname);
    formData.append('lastname', dataToSend.lastname);
    formData.append('phone_number', dataToSend.phone_number);
    if (dataToSend.validity_date) {
      formData.append('validity_date', dataToSend.validity_date);
    }
    if (dataToSend.email) {
      formData.append('email', dataToSend.email);
    }
    if (dataToSend.entity_id) {
      formData.append('entity_id', dataToSend.entity_id.toString());
    } else if (dataToSend.entity) {
      formData.append('entity[siren]', dataToSend.entity.siren);
      formData.append('entity[siret]', dataToSend.entity.siret);
      formData.append(
        'entity[legal_category]',
        dataToSend.entity.legal_category
      );
      formData.append('entity[company_name]', dataToSend.entity.company_name);
      formData.append('entity[zipcode]', dataToSend.entity.zipcode);
      formData.append('entity[city]', dataToSend.entity.city);
      formData.append('entity[address]', dataToSend.entity.address);
    }
    formData.append('file', dataToSend.file);

    const response = await axiosClient.post(
      urlPostMarValidateEnergyAudit(id),
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
    return response.data.data as IWorksiteMar;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const validateHousingAnalysis = async (
  id: number,
  dataToSend: IHousingAnalysisDataSend
) => {
  try {
    const formData = new FormData();

    formData.append('visit_date', dataToSend.visit_date);
    formData.append('unfit', dataToSend.unfit === 'true' ? '1' : '0');
    formData.append(
      'enhancement',
      dataToSend.enhancement === 'true' ? '1' : '0'
    );
    formData.append('adaptation', dataToSend.adaptation === 'true' ? '1' : '0');
    formData.append('file', dataToSend.file);

    const response = await axiosClient.post(
      urlPostMarValidateHousingAnalysis(id),
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
    return response.data.data as IWorksiteMar;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const fetchEnergeticClass = async (dataToSend: {
  cep: number;
  eges: number;
}) => {
  try {
    const response = await axiosClient.post(urlPostEnergyClass, dataToSend);
    return response.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const fetchMarOperationsWithZipCode = async (zipcode: string) => {
  try {
    const response = await axiosClient.post(urlPostMarOperationsList, {
      zipcode,
    });
    return response.data.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const getScenarioPrime = async (dataToSend: {
  initial_class: string | null;
  final_class: string | null;
  precarity_type: number | null;
  local_primes: number | null;
  cost_ht: number;
  cost_ttc: number;
}) => {
  try {
    const response = await axiosClient.post(
      urlPostMarScenarioPrime,
      dataToSend
    );
    return response.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const validateOneScenario = async (
  worksiteId: number,
  formData: FormData
) => {
  try {
    const response = await axiosClient.post(
      urlPostMarValidateOneScenario(worksiteId),
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
    return response.data.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const validateScenariosAndCalculations = async (worksiteId: number) => {
  try {
    const response = await axiosClient.post(
      urlPostMarValidateScenariosAndCalculations(worksiteId)
    );
    return response.data.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const getProsList = async (worksiteId: number) => {
  try {
    const response = await axiosClient.post(urlPostMarProsList(worksiteId), {
      pagination: {
        page: 1,
        perPage: 100,
      },
    });
    return response.data.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const addPro = async (
  worksiteId: number,
  dataToSend: {
    entity: {
      siret: string;
      siren: string;
      legal_category: string;
      company_name: string;
      zipcode: string;
      city: string;
      address: string;
    };
    entity_id: number | null;
  }
) => {
  try {
    const response = await axiosClient.post(
      urlPostMarPro(worksiteId),
      dataToSend.entity_id ? { entity_id: dataToSend.entity_id } : dataToSend
    );
    return response.data.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const validateOneQuote = async (
  worksiteId: number,
  formData: FormData
) => {
  try {
    const response = await axiosClient.post(
      urlPostMarValidateOneQuote(worksiteId),
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const generateQuoteWorksCertificate = async (worksiteId: number) => {
  try {
    const response = await axiosClient.get(
      urlGetQuoteWorksCertificate(worksiteId)
    );
    return response.data.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const validateQuotes = async (worksiteId: number) => {
  try {
    const response = await axiosClient.post(
      urlPostMarValidateQuotes(worksiteId)
    );
    return response.data.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const deleteQuotes = async (worksiteId: number, quotesIds: number[]) => {
  try {
    const response = await axiosClient.delete(urlDeleteMarQuotes(worksiteId), {
      data: quotesIds,
    });
    return response.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const validateAdministrativeMandate = async (
  worksiteId: number,
  dataToSend: any
) => {
  try {
    const response = await axiosClient.post(
      urlPostmarValidateAdministrativeMandate(worksiteId),
      dataToSend
    );
    return response.data.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const generateMandateWithType = async (
  worksiteId: number,
  dataToSend: any
) => {
  try {
    const response = await axiosClient.post(
      urlPostMandateGeneration(worksiteId),
      dataToSend
    );
    return response.data.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const generateAdditionalCommitmentsCertificate = async (
  worksiteId: number,
  dataToSend: {
    reference: string;
  }
) => {
  try {
    const response = await axiosClient.post(
      urlPostGenerateAdditionalCommitmentsCertificate(worksiteId),
      dataToSend
    );
    return response.data.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const validateCeeAnah = async (
  worksiteId: number,
  dataToSend: {
    reference: string;
  }
) => {
  try {
    const response = await axiosClient.post(
      urlPostMarValidateCeeAnah(worksiteId),
      dataToSend
    );
    return response.data.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const getDocumentsMar = async (worksiteId: number) => {
  try {
    const response = await axiosClient.get(urlGetMarDocuments(worksiteId));
    return response.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const getExportDocumentsMar = async (worksiteId: number) => {
  try {
    const response = await axiosClient.get(
      urlGetMarDocumentsDownload(worksiteId),
      {
        responseType: 'blob',
      }
    );
    return response.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const validateDeposit = async (
  worksiteId: number,
  dataToSend: {
    deposit_date: string;
  }
) => {
  try {
    const response = await axiosClient.post(
      urlPostMarValidateDeposit(worksiteId),
      dataToSend
    );
    return response.data.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const postLinkedFile = async (
  file: File,
  linkedFile: ILinkedFile,
  customName?: string,
  canDuplicate?: boolean
) => {
  const { relation_ids, relation_type, file_type, mandatory } = linkedFile;
  const formData = new FormData();
  formData.append('file', file);
  if (relation_ids) {
    relation_ids.forEach((id) => {
      formData.append('relation_ids[]', id.toString());
    });
  }
  formData.append('relation_type', relation_type || '');
  formData.append('file_type', file_type.toString());
  formData.append('mandatory', mandatory ? '1' : '0');
  formData.append('can_duplicate', canDuplicate ? '1' : '0');
  if (customName) {
    formData.append('custom_name', customName);
  }
  try {
    const newDocument = await axiosClient.post(urlPostLinkedFiles, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return newDocument.data.data;
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors
    ) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const validateNotificationOfGrant = async (
  worksiteId: number,
  dataToSend: {
    primeAmount: string;
    agreementDate: string;
    file: File;
  }
) => {
  try {
    const { primeAmount, agreementDate, file } = dataToSend;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('prime_amount', primeAmount);
    formData.append('agreement_date', agreementDate);
    const response = await axiosClient.post(
      urlPostMarValidateNotificationOfGrant(worksiteId),
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
    return response.data.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const validateWorkInProgress = async (
  worksiteId: number,
  dataToSend: {
    start_date: string;
    end_date: string;
  }
) => {
  try {
    const response = await axiosClient.post(
      urlPostMarValidateWorkInProgress(worksiteId),
      dataToSend
    );
    return response.data.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const getQuotesSelected = async (worksiteId: number) => {
  try {
    const response = await axiosClient.get(urlGetMarQuotesSelected(worksiteId));
    return response.data.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const validateInvoiceEndOfWork = async (
  worksiteId: number,
  dataToSend: IEndOfWorkDataToSend
) => {
  try {
    const formData = new FormData();

    // Ajout du fichier
    formData.append('file', dataToSend.file);

    // Ajout de l'ID du devis
    formData.append('mar_devis_id', dataToSend.mar_devis_id.toString());

    // Ajout de la date
    formData.append('date', dataToSend.date);

    // Ajout des gestes
    dataToSend.mar_gestes.forEach((gesture, index) => {
      formData.append(
        `gestes[${index}][geste_id]`,
        gesture.geste_id.toString()
      );
      formData.append(`gestes[${index}][cost_ht]`, gesture.cost_ht.toString());
      formData.append(
        `gestes[${index}][cost_ttc]`,
        gesture.cost_ttc.toString()
      );
      if (gesture.description) {
        formData.append(`gestes[${index}][description]`, gesture.description);
      }
    });
    const response = await axiosClient.post(
      urlPostMarValidateInvoice(worksiteId),
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
    return response.data.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const generateInvoiceWorksCertificate = async (worksiteId: number) => {
  try {
    const response = await axiosClient.get(
      urlGetInvoiceWorksCertificate(worksiteId)
    );
    return response.data.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const validateEndOfWork = async (worksiteId: number) => {
  try {
    const response = await axiosClient.post(
      urlPostMarValidateEndOfWork(worksiteId)
    );
    return response.data.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const validateAfterWork = async (
  worksiteId: number,
  dataToSend: {
    finalVisitDate: string;
    reportFile: File;
    reportDate: string;
    pvFile?: File;
    pvDate?: string;
  }
) => {
  try {
    const { finalVisitDate, reportFile, reportDate, pvFile, pvDate } =
      dataToSend;
    const formData = new FormData();
    formData.append('final_visit_date', finalVisitDate);
    formData.append('report_file', reportFile);
    formData.append('report_date', reportDate);
    if (pvFile) {
      formData.append('pv_file', pvFile);
    }
    if (pvDate) {
      formData.append('pv_date', pvDate);
    }
    const response = await axiosClient.post(
      urlPostMarValidateAfterWork(worksiteId),
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
    return response.data.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const validatePaymentLetter = async (
  worksiteId: number,
  dataToSend: {
    requestFile: File;
    paymentRequestDate: string;
    receiptFile: File;
    paymentReceiptDate: string;
  }
) => {
  try {
    const { requestFile, paymentRequestDate, receiptFile, paymentReceiptDate } =
      dataToSend;
    const formData = new FormData();
    formData.append('request_file', requestFile);
    formData.append('payment_request_date', paymentRequestDate);
    formData.append('receipt_file', receiptFile);
    formData.append('payment_receipt_date', paymentReceiptDate);
    const response = await axiosClient.post(
      urlPostMarValidatePaymentLetter(worksiteId),
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
    return response.data.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const deleteOneLinkedFile = async (id: number) => {
  try {
    const response = await axiosClient.delete(urlLinkedFileAction(id));
    return response.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};

export const getWorksitesListMar = async (payload: {
  pagination: { page: number; perPage: number };
  sort_and_filter: ISortAndFilterType;
  keyword?: string;
}) => {
  try {
    const response = await axiosClient.post(urlPostMarWorksitesList, payload);
    return response.data;
  } catch (error) {
    if (error.response?.data?.errors) {
      toast.error(error.response.data.errors);
    }
    return false;
  }
};
