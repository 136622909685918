import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ContractCreationContext } from '@models/contractCreation/utils/contractCreationContext';
import {
  ContractTypes,
  ConventionModelStatus,
  CreationSteps,
} from '@models/contractCreation/utils/enums';
import { ContractStepsContainer } from '@models/contractCreation/components/ContractStepsContainer';
import {
  IContractDraftType,
  IContractOperationPrice,
  IContractPartnerInfo,
  IContractPaymentCondition,
  IContractReferents,
  IObligeList,
  IRelatedContract,
  IVolumeType,
} from '@models/contractCreation/utils/contractCreationTypes';
import {
  initialContractContact,
  initialContractPaymentConditions,
} from '@utils/initialState';
import { IContractType } from '@models/contracts/utils/contractTypes';
import { ContractCreationSidebar } from '@models/contractCreation/components/ContractCreationSidebar';
import { ContractTunnel } from '@models/contractCreation/components/ContractTunnel';
import {
  IAssociateType,
  ITunnelType,
} from '@models/contracts/utils/newContractTypes';
import { AssociateSelection } from '@models/contractCreation/components/AssociateSelection';
import { SaleProfileModal } from '@models/contractCreation/components/SaleProfileModal';
import { IUserType } from '@models/auth/utils/types';
import { IOperationType } from '@models/conventions/utils/conventionTypes';
import { initialWorksite } from '@models/worksiteCreation/utils/initialsValues/worksitesInitialValues';
import {
  IBeneficiary,
  IBeneficiaryAddress,
} from '@models/beneficiaries/utils/beneficiariesType';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { HeaderContext } from '@context/headerContext';
import {
  getDraftData,
  getModelData,
  storeContractDraft,
  upsertConventionModel,
} from '@models/contractCreation/apiRequests/contractCreationRequests';
import {
  getModelPayload,
  getPayload,
} from '@models/contractCreation/utils/payloads';
import { GlobalContext } from '@context/globalContext';
import { updateState } from '@models/contractCreation/utils/functions';
import { duplicateConvention } from '@models/conventions/apiRequests/conventionRequests';
import { useTranslation } from 'react-i18next';
import { CONTRACTS_ROUTES, CONTRACTS_ROUTES_WITH_ID } from '@utils/routesUrls';

interface IContractCreationProps {
  isConventionModel?: boolean;
}

function ContractCreation({ isConventionModel }: IContractCreationProps) {
  const { t } = useTranslation();
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { updateTitleHeader } = useContext(HeaderContext);
  const { userView, globalEnum } = useContext(GlobalContext);

  const [tunnel, setTunnel] = useState<ITunnelType>();
  const [tunnels, setTunnels] = useState<ITunnelType[]>([]);

  const [listAssociated, setListAssociated] = useState<{
    recents: IAssociateType[];
    all: IAssociateType[];
  }>({ recents: [], all: [] });

  const [contactList, setContactList] = useState<IUserType[]>([]);

  const [listOperations, setListOperations] = useState<IOperationType[] | null>(
    null
  );
  // Step
  const [activeStep, setActiveStep] = useState<number>(CreationSteps.TUNNEL);
  const [lastStep, setLastStep] = useState<number>(-1);

  // contract data
  const [contract, setContract] = useState<IContractType>();

  // Infos générales
  const [editMode, setEditMode] = useState<boolean>(false);
  const [invitationProcess, setInvitationProcess] = useState<boolean>(false);
  const [conventionModel, setConventionModel] = useState<boolean>(false);
  const [referenceOpx, setReferenceOpx] = useState<string>('');
  const [referencePerso, setReferencePerso] = useState<string>('');
  const [rai, setRai] = useState<{ value: number; label: string }>({
    value: 0,
    label: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [referenceConventionModel, setReferenceConventionModel] =
    useState<string>('');
  const [relatedContract, setRelatedContract] =
    useState<IRelatedContract>(null);
  const [beneficiaryType, setBeneficiaryType] = useState<number>(0);
  const [validationDelay, setValidationDelay] = useState<number>(0);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [contractType, setContractType] = useState<number>(0);
  const [origination, setOrigination] = useState<number>(0);
  const [worksiteAddresses, setWorksiteAddresses] = useState<
    IBeneficiaryAddress[]
  >([]);
  const [raiMention, setRaiMention] = useState<string>('');
  const [listObliges, setListObliges] = useState<IObligeList[]>([]);
  const [isElectronicSignature, setIsElectronicSignature] =
    useState<boolean>(false);
  const [canManageWorksiteDocuments, setCanManageWorksiteDocuments] =
    useState<boolean>(false);
  const [hasCdpCharterSignatory, setHasCdpCharterSignatory] =
    useState<boolean>(false);

  // Volumes
  const initialVolume = { classic: '', precaire: '' };
  const [volumeMin, setVolumeMin] = useState<IVolumeType>(initialVolume);
  const [volumeMax, setVolumeMax] = useState<IVolumeType>(initialVolume);
  const [penalties, setPenalties] = useState<IVolumeType>(initialVolume);
  const [deliveryVolume, setDeliveryVolume] = useState<string>('');
  const [salePrices, setSalePrices] = useState<IVolumeType>(initialVolume);

  // Partner
  const [entityTo, setEntityTo] = useState<IContractPartnerInfo>(
    initialContractContact
  );
  // AMO
  const [amo, setAmo] = useState<IContractPartnerInfo>(initialContractContact);

  // Beneficiary B2C
  const [beneficiary, setBeneficiary] = useState<IBeneficiary>(
    initialWorksite.beneficiary
  );

  // Apporteur d'affaires
  const [intermediaryBusiness, setIntermediaryBusiness] =
    useState<IContractPartnerInfo>(initialContractContact);
  const [commission, setCommission] = useState<number>(0);

  // Referents
  const [referents, setReferents] = useState<IContractReferents | null>(null);

  // Operations
  const [operationPrices, setOperationPrices] = useState<
    IContractOperationPrice[]
  >([]);

  // Payment Conditions
  const [paymentConditions, setPaymentConditions] = useState<
    IContractPaymentCondition[]
  >([initialContractPaymentConditions, initialContractPaymentConditions]);

  // contrat de vente
  const [saleProfile, setSaleProfile] = useState<number>(-1);
  const [partnerType, setPartnerType] = useState<number>(0);

  const [steps, setSteps] = useState<{ label: string; value: number }[]>([]);

  const [payloadData, setPayloadData] = useState<IContractDraftType | null>(
    null
  );

  const [incentiveType, setIncentiveType] = useState<number>(2);

  const updateSteps = (step: number) => {
    setActiveStep(step);
    if (step > lastStep) setLastStep(step);
  };

  const changeStep = (direction: 'next' | 'back' | 'end' | number) => {
    const activeStepIndex = steps.findIndex((s) => s.value === activeStep);

    if (typeof direction === 'number') {
      updateSteps(direction);
    } else if (direction === 'end') {
      if (isConventionModel) updateSteps(CreationSteps.INVITATION);
      else updateSteps(CreationSteps.DOCUMENTS);
    } else if (direction === 'next') {
      const nextStep = steps.find((s, i) => i === activeStepIndex + 1);
      if (nextStep) updateSteps(nextStep.value);
    } else {
      const previousStep = steps.find((s, i) => i === activeStepIndex - 1);
      if (previousStep) setActiveStep(previousStep.value);
    }
  };

  const { GENERAL, TUNNEL, PARTNER_CHOICE, SALE_PROFILE } = CreationSteps;

  const contractCreationContextValue = useMemo(
    () => ({
      contract,
      updateContract: setContract,
      editMode,
      referenceOpx,
      updateReferenceOpx: setReferenceOpx,
      referencePerso,
      updateReferencePerso: setReferencePerso,
      rai,
      updateRai: setRai,
      relatedContract,
      updateRelatedContract: setRelatedContract,
      beneficiaryType,
      updateBeneficiaryType: setBeneficiaryType,
      validationDelay,
      updateValidationDelay: setValidationDelay,
      startDate,
      updateStartDate: setStartDate,
      endDate,
      updateEndDate: setEndDate,
      activeStep,
      updateActiveStep: updateSteps,
      lastStep,
      volumeMin,
      updateVolumeMin: setVolumeMin,
      volumeMax,
      updateVolumeMax: setVolumeMax,
      entityTo,
      updateEntityTo: setEntityTo,
      referents,
      updateReferents: setReferents,
      operationPrices,
      updateOperationPrices: setOperationPrices,
      amo,
      updateAmo: setAmo,
      paymentConditions,
      updatePaymentConditions: setPaymentConditions,
      tunnel,
      updateTunnel: setTunnel,
      tunnels,
      updateTunnels: setTunnels,
      saleProfile,
      updateSaleProfile: setSaleProfile,
      contractType,
      updateContractType: setContractType,
      penalties,
      updatePenalties: setPenalties,
      deliveryVolume,
      updateDeliveryVolume: setDeliveryVolume,
      commission,
      updateCommission: setCommission,
      listAssociated,
      updateListAssociated: setListAssociated,
      contactList,
      updateContactList: setContactList,
      listOperations,
      updateListOperations: setListOperations,
      salePrices,
      updateSalePrices: setSalePrices,
      origination,
      updateOrigination: setOrigination,
      intermediaryBusiness,
      updateIntermediaryBusiness: setIntermediaryBusiness,
      worksiteAddresses,
      updateWorksiteAddresses: setWorksiteAddresses,
      steps,
      changeStep,
      beneficiary,
      updateBeneficiary: setBeneficiary,
      partnerType,
      updatePartnerType: setPartnerType,
      payloadData,
      updatePayloadData: setPayloadData,
      raiMention,
      updateRaiMention: setRaiMention,
      listObliges,
      updateListObliges: setListObliges,
      incentiveType,
      updateIncentiveType: setIncentiveType,
      invitationProcess: setInvitationProcess,
      conventionModel,
      isElectronicSignature,
      updateIsElectronicSignature: setIsElectronicSignature,
      canManageWorksiteDocuments,
      updateCanManageWorksiteDocuments: setCanManageWorksiteDocuments,
      hasCdpCharterSignatory,
      updateHasCdpCharterSignatory: setHasCdpCharterSignatory,
    }),
    [
      contract,
      editMode,
      referenceOpx,
      referencePerso,
      rai,
      relatedContract,
      beneficiaryType,
      validationDelay,
      startDate,
      endDate,
      activeStep,
      lastStep,
      volumeMax,
      volumeMin,
      entityTo,
      referents,
      operationPrices,
      amo,
      paymentConditions,
      tunnel,
      tunnels,
      saleProfile,
      contractType,
      penalties,
      deliveryVolume,
      commission,
      listAssociated,
      contactList,
      listOperations,
      salePrices,
      origination,
      intermediaryBusiness,
      worksiteAddresses,
      steps,
      beneficiary,
      partnerType,
      payloadData,
      raiMention,
      listObliges,
      incentiveType,
      invitationProcess,
      conventionModel,
      isElectronicSignature,
      canManageWorksiteDocuments,
      hasCdpCharterSignatory,
    ]
  );

  const isCopy = useMemo(() => {
    return state?.copy || id;
  }, [state]);

  const invitationStep = invitationProcess
    ? t('buttons.save_and_quit')
    : t('buttons.save_without_inviting');

  const getData = async () => {
    let draftData;
    if (isConventionModel) {
      draftData = await getModelData(Number(id));
      setReferenceConventionModel(draftData.reference);
    } else if (state?.copy) draftData = await duplicateConvention(Number(id));
    else draftData = await getDraftData(Number(id));

    const payload: IContractDraftType = draftData?.payloads;
    setPayloadData(payload);

    if (payload) {
      const modelDone = isConventionModel && draftData.status > 1;

      const step =
        modelDone || !payload.last_step
          ? CreationSteps.GENERAL
          : payload.last_step;

      setActiveStep(step);
      setLastStep(modelDone ? CreationSteps.PAYMENT : step);

      updateState(payload, contractCreationContextValue, isCopy);
    }
  };

  const modalsBeforeCreation = useMemo(() => {
    switch (activeStep) {
      case TUNNEL:
        return !id ? (
          <ContractTunnel isNewTemplate={isConventionModel} />
        ) : (
          <div />
        );
      case PARTNER_CHOICE:
        return <AssociateSelection />;
      case SALE_PROFILE:
        return <SaleProfileModal />;
      default:
        return <div />;
    }
  }, [activeStep, tunnel]);

  const saveDraft = async () => {
    if (userView) {
      setIsLoading(true);
      try {
        const copyId = id ? Number(id) : null;
        const payload = getPayload(
          contractCreationContextValue,
          userView,
          copyId,
          true
        );
        await storeContractDraft(
          contractType,
          referenceOpx,
          referencePerso,
          payload
        );
        navigate(CONTRACTS_ROUTES.CONTRACTS);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      navigate(`/contracts/edit/${id}`, {
        state: { copy: false },
      });
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const saveModelDraft = async () => {
    if (userView) {
      const copyId = id ? Number(id) : null;
      const payload = getModelPayload(
        contractCreationContextValue,
        userView,
        copyId,
        true
      );
      const res = await upsertConventionModel(
        ConventionModelStatus.IN_PROGRESS,
        payload
      );

      if (res) navigate(CONTRACTS_ROUTES.CONTRACTS);
    }
  };

  useEffect(() => {
    if (isConventionModel) setConventionModel(true);
    const label = globalEnum.contract_type[contractType];

    let titleHeaderConventionModel = '';

    if (label) {
      const name = label.charAt(0).toLowerCase() + label.slice(1);
      if (isConventionModel) {
        titleHeaderConventionModel =
          referenceConventionModel !== ''
            ? `${t('contract.contractual_model')} ${referenceConventionModel}`
            : `${t('contract.new_template')} ${name}`;
      }
      updateTitleHeader(
        titleHeaderConventionModel !== ''
          ? titleHeaderConventionModel
          : `${t('global.new')} ${name}`
      );
    }
  }, [contractType, id]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      navigate(CONTRACTS_ROUTES_WITH_ID(id as string).CONTRACTS_EDIT, {
        state: { copy: false },
      });
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (Number(id) > 0) {
      getData();
      setEditMode(true);
    } else setEditMode(false);
  }, [id]);

  useEffect(() => {
    if (state && state.contractType && tunnels.length > 0) {
      const selectedTunnel = tunnels.find(
        (elt) => elt.contractType === state.contractType
      );
      if (selectedTunnel && !tunnel) setTunnel(selectedTunnel);

      if (contractType < 1) setContractType(state.contractType);
      const nextStep = [ContractTypes.VENTE, ContractTypes.DELEGATION].includes(
        state.contractType
      )
        ? CreationSteps.SALE_PROFILE
        : CreationSteps.PARTNER_CHOICE;
      if (activeStep < 1) setActiveStep(nextStep);
    } else {
      setTunnel(undefined);
      setContractType(0);
      setActiveStep(0);
    }
  }, [state, tunnels]);

  useEffect(() => {
    if (relatedContract?.incentive_type && !payloadData?.incentive_type) {
      setIncentiveType(relatedContract?.incentive_type);
    }
  }, [relatedContract]);

  return (
    <ContractCreationContext.Provider value={contractCreationContextValue}>
      <div>
        {activeStep >= CreationSteps.GENERAL && (
          <div className="mt-5 flex justify-end gap-5">
            {activeStep === CreationSteps.DOCUMENTS && (
              <ButtonOpx
                label={t('buttons.cancel')}
                type="secondary"
                onClick={() => navigate(CONTRACTS_ROUTES.CONTRACTS)}
              />
            )}
            {isConventionModel ? (
              <ButtonOpx
                label={
                  activeStep === CreationSteps.INVITATION
                    ? invitationStep // if invitation triggered display 'Save and quit, else Save without inviting'
                    : t('buttons.save_and_quit') // if creationstep document, display only Save and quit
                }
                type="primary"
                isLoading={isLoading}
                onClick={() =>
                  activeStep === CreationSteps.INVITATION
                    ? navigate(
                        CONTRACTS_ROUTES_WITH_ID(Number(relatedContract?.id))
                          .CONVENTION_MODEL
                      )
                    : saveModelDraft()
                }
              />
            ) : (
              <ButtonOpx
                label={
                  activeStep === CreationSteps.DOCUMENTS
                    ? t('buttons.contract_validate')
                    : t('buttons.save_and_quit')
                }
                type="primary"
                disabled={
                  contract &&
                  contract.linkedFiles &&
                  contract.linkedFiles.length > 0
                }
                isLoading={isLoading}
                onClick={() =>
                  activeStep === CreationSteps.DOCUMENTS
                    ? navigate(CONTRACTS_ROUTES.CONTRACTS)
                    : saveDraft()
                }
              />
            )}
          </div>
        )}
        <div className="flex space-x-[1.5rem] py-[1.5rem] items-start">
          {activeStep < GENERAL ? (
            modalsBeforeCreation
          ) : (
            <>
              <div className="flex flex-col w-3/12 gap-[1rem]">
                <ContractCreationSidebar
                  isNewTemplate={isConventionModel}
                  setSteps={setSteps}
                />
              </div>
              <div className="w-9/12">
                <ContractStepsContainer />
              </div>
            </>
          )}
        </div>
      </div>
    </ContractCreationContext.Provider>
  );
}

export { ContractCreation };

ContractCreation.defaultProps = {
  isConventionModel: false,
};
