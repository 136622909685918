import { useContext } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { AuthContext } from '@context/authContext';

import { Login } from '@pages/auth/Login';
import { ForgotPassword } from '@pages/auth/ForgotPassword';
import { ResetPassword } from '@pages/auth/ResetPassword';

import { Worksites } from '@pages/Worksites';
import { WorksiteCreation } from '@pages/WorksiteCreation';
import { Design } from '@pages/Design';
import { Beneficiaries } from '@pages/Beneficiaries';
import { Contracts } from '@pages/Contracts';
import { Deposits } from '@pages/Deposits';
import { Partners } from '@pages/Partners';
import { Dashboard } from '@pages/Dashboard';
import { ConventionSheet } from '@pages/conventions/ConventionSheet';
import { ContractSheet } from '@pages/contracts/ContractSheet';
import Requests from '@pages/Requests';
import { MyAccount } from '@pages/MyAccount';
import { Transfer } from '@pages/Transfer';
import { Lot } from '@pages/Lot';
import { Deposit } from '@pages/Deposit';
import { Cofrac } from '@pages/Cofrac';
import { Registration } from '@pages/auth/Registration';
import { InternalRegistration } from '@pages/auth/InternalRegistration';
import { BeneficiaryView } from '@pages/BeneficiaryView';
import { ContractCreation } from '@pages/ContractCreation';
import { Users } from '@pages/admin/Users';
import { Settings } from '@pages/Settings';
import ProtectedRedirect from '@components/router/ProtectedRedirect';
import { DashboardDev } from '@pages/DashboardDev';
import { SubcontractorObjection } from '@pages/auth/SubcontractorObjection';
import CertificationSheet from '@pages/CertificationSheet';
import { DownloadArchive } from '@pages/auth/DownloadArchive';
import { Brands } from '@pages/Brands';
import { BrandsMaterials } from '@pages/BrandsMaterials';
import { RegistrationAlmostDone } from '@pages/auth/RegistrationAlmostDone';
import { ConventionModelSheet } from '@pages/conventions/ConventionModelSheet';
import {
  ADMIN_ROUTES,
  ADMIN_ROUTES_WITH_ID,
  AUTH_ROUTES,
  BENEFICIARIES_ROUTES,
  BENEFICIARIES_ROUTES_WITH_ID,
  CONTRACTS_ROUTES,
  CONTRACTS_ROUTES_WITH_ID,
  CONTRACTS_ROUTES_WITH_PARAMS,
  CUSTOMERS_ROUTES,
  DASHBOARD_ROUTES,
  DEPOSITS_ROUTES,
  DEPOSITS_ROUTES_WITH_ID,
  DOWNLOAD_ARCHIVE_ROUTES_WITH_PARAMS,
  MAR_ROUTES,
  MAR_ROUTES_WITH_ID,
  PARTNERS_ROUTES,
  PARTNERS_ROUTES_WITH_ID,
  SETTINGS_ROUTES,
  SETTINGS_ROUTES_WITH_ID,
  SUBCONTRACTOR_ROUTES_WITH_PARAMS,
  USERS_ROUTES,
  WORKSITES_ROUTES,
  WORKSITES_ROUTES_WITH_ID,
} from '@utils/routesUrls';
import WorksiteCreationMar from '@pages/mar/WorksiteCreationMar';
import WorksitesListMar from '@pages/mar/WorksitesListMar';
import { CustomersList } from '@pages/mar/CustomersList';

function Router(): JSX.Element {
  const { token } = useContext(AuthContext);
  return token ? (
    <Routes>
      <Route
        path={AUTH_ROUTES.PROTECTED_REDIRECT}
        element={<Navigate to={DASHBOARD_ROUTES.DASHBOARD} />}
      />
      <Route path={DASHBOARD_ROUTES.DASHBOARD} element={<Dashboard />} />
      <Route path={DASHBOARD_ROUTES.DASHBOARD_DEV} element={<DashboardDev />} />
      <Route path={SETTINGS_ROUTES.SETTINGS} element={<Settings />} />
      <Route path={SETTINGS_ROUTES.SETTINGS_BRANDS} element={<Brands />} />
      <Route
        path={SETTINGS_ROUTES_WITH_ID(':id').SETTINGS_BRANDS_VIEW}
        element={<BrandsMaterials />}
      />

      {/* CHANTIERS */}
      <Route
        path={WORKSITES_ROUTES.WORKSITES_NEW}
        element={<WorksiteCreation />}
      />
      <Route
        path={WORKSITES_ROUTES_WITH_ID(':id').WORKSITES_NEW_ID}
        element={<WorksiteCreation />}
      />
      <Route path={WORKSITES_ROUTES.WORKSITES} element={<Worksites />} />
      <Route
        path={WORKSITES_ROUTES_WITH_ID(':id').WORKSITES_VIEW}
        element={<Worksites />}
      />

      {/* BENEFICIAIRES */}
      <Route
        path={BENEFICIARIES_ROUTES.BENEFICIARIES}
        element={<Beneficiaries />}
      />
      <Route
        path={BENEFICIARIES_ROUTES_WITH_ID(':id').BENEFICIARY_VIEW}
        element={<Beneficiaries />}
      />
      <Route
        path={BENEFICIARIES_ROUTES_WITH_ID(':uuid').BENEFICIARY_WORKSITE_VIEW}
        element={<BeneficiaryView />}
      />

      {/* CONTRATS */}
      <Route path={CONTRACTS_ROUTES.CONTRACTS} element={<Contracts />} />
      <Route
        path={CONTRACTS_ROUTES.CONTRACTS_CREATION}
        element={<ContractCreation />}
      />
      <Route
        path={CONTRACTS_ROUTES_WITH_PARAMS(':type', ':id').CONTRACT_VIEW}
        element={<ContractSheet />}
      />
      <Route
        path={CONTRACTS_ROUTES_WITH_ID(':id').CONTRACTS_EDIT}
        element={<ContractCreation />}
      />
      <Route
        path={CONTRACTS_ROUTES_WITH_PARAMS(':type', ':id').CONVENTION_VIEW}
        element={<ConventionSheet />}
      />

      {/* CONTRATS - Convention Model */}
      <Route
        path={CONTRACTS_ROUTES.CONVENTION_MODEL}
        element={<ContractCreation isConventionModel />}
      />
      <Route
        path={CONTRACTS_ROUTES_WITH_ID(':id').CONVENTION_MODEL_EDIT}
        element={<ContractCreation isConventionModel />}
      />
      <Route
        path={CONTRACTS_ROUTES_WITH_ID(':id').CONVENTION_MODEL}
        element={<ConventionModelSheet />}
      />

      {/* DEPOTS */}
      <Route
        path={DEPOSITS_ROUTES_WITH_ID(':id').TRANSFER_VIEW}
        element={<Transfer />}
      />
      <Route path={DEPOSITS_ROUTES_WITH_ID(':id').LOT_VIEW} element={<Lot />} />
      <Route path={DEPOSITS_ROUTES.DEPOSITS} element={<Deposits />} />
      <Route
        path={DEPOSITS_ROUTES_WITH_ID(':id').DEPOSIT_VIEW}
        element={<Deposit />}
      />

      {/* PARTENAIRES */}
      <Route path={PARTNERS_ROUTES.PARTNERS} element={<Partners />} />
      <Route
        path={PARTNERS_ROUTES_WITH_ID(':id').PARTNER_VIEW}
        element={<Partners />}
      />
      <Route
        path={
          SUBCONTRACTOR_ROUTES_WITH_PARAMS(':installerId', ':id')
            .INSTALLER_SUBCONTRACTOR_VIEW
        }
        element={<Partners />}
      />
      <Route path={PARTNERS_ROUTES.INSTALLERS} element={<Partners />} />
      <Route
        path={PARTNERS_ROUTES_WITH_ID(':id').INSTALLER_VIEW}
        element={<Partners />}
      />
      <Route
        path={PARTNERS_ROUTES_WITH_ID(':id').CERTIFICATION_VIEW}
        element={<CertificationSheet />}
      />
      <Route path={PARTNERS_ROUTES.REQUESTS} element={<Requests />} />
      <Route path={PARTNERS_ROUTES.DESIGN} element={<Design />} />
      <Route path={PARTNERS_ROUTES.COFRAC} element={<Cofrac />} />
      <Route
        path={PARTNERS_ROUTES_WITH_ID(':id').COFRAC_VIEW}
        element={<Cofrac />}
      />
      <Route path={USERS_ROUTES.PROFILE} element={<MyAccount />} />
      <Route path={ADMIN_ROUTES.ADMIN_USERS} element={<Users />} />
      <Route
        path={ADMIN_ROUTES_WITH_ID(':id').ADMIN_USER_VIEW}
        element={<Users />}
      />
      <Route
        path={ADMIN_ROUTES.ADMIN_WORKSITES}
        element={<Worksites adminMode />}
      />
      <Route
        path={ADMIN_ROUTES_WITH_ID(':id').ADMIN_WORKSITE_VIEW}
        element={<Worksites adminMode />}
      />
      <Route
        path={ADMIN_ROUTES.ADMIN_WORKSITES_NEW}
        element={<WorksiteCreation adminMode />}
      />
      <Route
        path={ADMIN_ROUTES_WITH_ID(':id').ADMIN_WORKSITE_NEW_ID}
        element={<WorksiteCreation adminMode />}
      />
      <Route
        path={ADMIN_ROUTES.ADMIN_CONTRACTS}
        element={<Contracts adminMode />}
      />
      <Route
        path={DOWNLOAD_ARCHIVE_ROUTES_WITH_PARAMS(':worksiteId', ':fileName')}
        element={<DownloadArchive />}
      />

      {/* MAR */}
      <Route path={MAR_ROUTES.WORKSITES_LIST} element={<WorksitesListMar />} />
      <Route
        path={MAR_ROUTES.WORKSITES_NEW}
        element={<WorksiteCreationMar />}
      />
      <Route
        path={MAR_ROUTES_WITH_ID(':id').WORKSITES_NEW_ID}
        element={<WorksiteCreationMar />}
      />
      <Route path={CUSTOMERS_ROUTES.CUSTOMERS} element={<CustomersList />} />
    </Routes>
  ) : (
    <Routes>
      <Route path={AUTH_ROUTES.LOGIN} element={<Login />} />
      <Route path={AUTH_ROUTES.REGISTER} element={<Registration />} />
      <Route
        path={AUTH_ROUTES.INTERNAL_REGISTRATION}
        element={<InternalRegistration />}
      />
      <Route
        path={AUTH_ROUTES.REGISTER_FROM_MODEL}
        element={<Registration />}
      />
      <Route
        path={AUTH_ROUTES.REGISTRATION_ALMOST_DONE}
        element={<RegistrationAlmostDone />}
      />
      <Route path={AUTH_ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path={AUTH_ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
      <Route
        path={BENEFICIARIES_ROUTES_WITH_ID(':uuid').BENEFICIARY_WORKSITE_VIEW}
        element={<BeneficiaryView />}
      />
      <Route
        path={AUTH_ROUTES.SUBCONTRACTOR_OBJECTION}
        element={<SubcontractorObjection />}
      />
      <Route
        path={AUTH_ROUTES.PROTECTED_REDIRECT}
        element={<ProtectedRedirect />}
      />
    </Routes>
  );
}

export default Router;
