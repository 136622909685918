import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import InitWorksiteMar from '@models/mar/components/initWorksite/InitWorksiteMar';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import {
  STEPS_WORKSITE_MAR_COMPLETION,
  STEPS_WORKSITE_MAR_INIT,
} from '@models/mar/utils/enums';
import { ICustomer, IPro, IWorksiteMar } from '@models/mar/utils/marTypes';
import { IWorksiteAddress } from '@models/worksiteCreation/utils/types/worksitesType';
import { worksiteAddressInitial } from '@models/worksiteCreation/utils/initialsValues/createWorksiteInitialsValues';
import { HeaderContext } from '@context/headerContext';
import {
  fetchMarOperationsWithZipCode,
  getProsList,
  getWorksiteDataWithId,
} from '@models/mar/apiRequests/worksiteCreationMarRequests';
import WorksiteMarCompletion from '@models/mar/components/worksiteCompletion/WorksiteMarCompletion';
import { IOperation } from '@models/worksiteCreation/utils/types/operationTypes';
import { ILabelValue } from '../../types/globalTypes';

function WorksiteCreationMar() {
  const { updateTitleHeader, updateSubtitleHeader } = useContext(HeaderContext);
  const { id } = useParams();

  const [isInit, setIsInit] = useState(!id);
  const [stepInit, setStepInit] = useState(
    STEPS_WORKSITE_MAR_INIT.BIND_CUSTOMER
  );
  const [stepCompletion, setStepCompletion] = useState(
    STEPS_WORKSITE_MAR_COMPLETION.TYPOLOGY_OF_HOUSING
  );
  const [customer, setCustomer] = useState<ICustomer>();
  const [worksiteAddress, setWorksiteAddress] = useState<IWorksiteAddress>(
    worksiteAddressInitial
  );
  const [worksiteData, setWorksiteData] = useState<IWorksiteMar>();
  const [buttonNextDisabled, setButtonNextDisabled] = useState(false);

  const [operationsList, setOperationsList] = useState<ILabelValue[]>();
  const [prosList, setProsList] = useState<IPro[]>([]);

  // Fonction pour réinitialiser l'état
  const resetState = () => {
    setIsInit(true);
    setStepInit(STEPS_WORKSITE_MAR_INIT.BIND_CUSTOMER);
    setStepCompletion(STEPS_WORKSITE_MAR_COMPLETION.TYPOLOGY_OF_HOUSING);
    setCustomer(undefined);
    setWorksiteAddress(worksiteAddressInitial);
    setWorksiteData(undefined);
    setButtonNextDisabled(false);
    setOperationsList(undefined);
    setProsList([]);
    updateTitleHeader('');
    updateSubtitleHeader('');
  };

  const updateWorksiteAddressFromKeyValue = (
    updates: Partial<IWorksiteAddress>
  ) => {
    setWorksiteAddress((prev) => ({ ...prev, ...updates }));
  };

  const getPros = async () => {
    const response = await getProsList(worksiteData?.id || 0);
    if (response) {
      setProsList(response);
    }
  };

  const worksiteCreationMarContextValue = useMemo(
    () => ({
      isInit,
      updateIsInit: setIsInit,
      stepInit,
      updateStepInit: setStepInit,
      customer,
      updateCustomer: setCustomer,
      worksiteAddress,
      updateWorksiteAddressFromKeyValue,
      worksiteData,
      updateWorksiteData: setWorksiteData,
      stepCompletion,
      updateStepCompletion: setStepCompletion,
      buttonNextDisabled,
      updateButtonNextDisabled: setButtonNextDisabled,
      operationsList,
      prosList,
      getPros,
    }),
    [
      isInit,
      stepInit,
      customer,
      worksiteAddress,
      worksiteData,
      stepCompletion,
      buttonNextDisabled,
      operationsList,
      prosList,
      getPros,
    ]
  );

  const getWorksiteData = async (idParam: number) => {
    const data = await getWorksiteDataWithId(idParam);
    if (data) {
      setWorksiteData(data);
      setStepCompletion(data.step);
      setCustomer(data.customer);
      const cadastrePart = data.cadastral_parcel
        ? data.cadastral_parcel.split(' ')
        : undefined;
      const addressToUse = data.address || data.customer.address;
      const addressParts = addressToUse.address.split(' ');
      // Récupérer le numéro de rue
      const numberStreet = addressParts[0];
      // Récupérer le nom de rue (tout sauf le premier élément)
      const streetName = addressParts.slice(1).join(' ');

      setWorksiteAddress({
        zipCode: addressToUse.postal_code,
        numberStreet,
        streetName,
        city: addressToUse.city,
        country: addressToUse.country,
        latitude: addressToUse.latitude,
        longitude: addressToUse.longitude,
        parcelPrefix: cadastrePart ? cadastrePart[0] : '',
        parcelSection: cadastrePart ? cadastrePart[1] : '',
        parcelNumber: cadastrePart ? cadastrePart[2] : '',
      });
    }
  };

  const getOperationsList = async () => {
    const operationsListGetted = await fetchMarOperationsWithZipCode(
      worksiteAddress?.zipCode
    );
    if (operationsListGetted) {
      setOperationsList(
        operationsListGetted.map((op: IOperation) => ({
          label: op.description,
          value: op.id.toString(),
        }))
      );
    }
  };

  useEffect(() => {
    if (!id) {
      resetState(); // Réinitialiser l'état si pas d'ID
    } else if (id && !worksiteData) {
      // Si l'ID est présent, faire un appel API pour récupérer les informations du chantier
      getWorksiteData(Number(id));
    }
  }, [id]);

  useEffect(() => {
    if (customer && !isInit && worksiteData?.id) {
      const addressToUse = worksiteData.address || customer.address;
      // Mettre à jour le titre du header
      updateTitleHeader(`${customer?.firstname} ${customer?.lastname}`);
      updateSubtitleHeader(
        `${addressToUse.address}, ${addressToUse.postal_code} ${addressToUse.city}`
      );
    }
  }, [customer, isInit, worksiteData]);

  useEffect(() => {
    if (
      !isInit &&
      worksiteAddress &&
      worksiteAddress.zipCode &&
      !operationsList
    ) {
      getOperationsList();
    }
  }, [isInit, worksiteAddress]);

  useEffect(() => {
    if (!isInit && prosList.length === 0 && worksiteData?.id) {
      getPros();
    }
  }, [isInit, worksiteData]);

  return (
    <WorksiteCreationMarContext.Provider
      value={worksiteCreationMarContextValue}
    >
      <div>{isInit ? <InitWorksiteMar /> : <WorksiteMarCompletion />}</div>
    </WorksiteCreationMarContext.Provider>
  );
}

export default WorksiteCreationMar;
