import LayoutMarDetailsStep from '@models/mar/components/worksiteCompletion/LayoutMarDetailsStep';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { WorksiteCreationMarContext } from '@models/mar/utils/worksiteCreationMarContext';
import {
  deleteQuotes,
  generateQuoteWorksCertificate,
  validateQuotes,
} from '@models/mar/apiRequests/worksiteCreationMarRequests';
import { ButtonOpx } from '@components/atomic/ButtonOpx';
import { STEPS_WORKSITE_MAR_COMPLETION } from '@models/mar/utils/enums';
import OnePro from '@models/mar/components/worksiteCompletion/stepsCompletion/quotes/OnePro';
import { IWorksiteMar } from '@models/mar/utils/marTypes';
import { TextError } from '@components/TextError';
import { createFileWithLinkedFile } from '@utils/functions';
import { downloadWhenGenerated } from '@models/mar/utils/functions';

function Quotes() {
  const { t } = useTranslation();
  const {
    worksiteData,
    updateButtonNextDisabled,
    updateWorksiteData,
    updateStepCompletion,
    operationsList,
    prosList,
  } = useContext(WorksiteCreationMarContext);

  const [buttonNextLoading, setButtonNextLoading] = useState(false);
  const [isPrefilled, setIsPrefilled] = useState(false);
  const [loadingGeneration, setLoadingGeneration] = useState(false);

  const { watch, control, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'pros',
  });

  const selectedScenario = (worksiteData as IWorksiteMar).scenarios?.find(
    (scenario) => scenario.selected
  );

  // Extraction de la liste des mar_pro_id non null
  const marProIdList = watch('pros')
    ? watch('pros')
        .map((pro: any, index: number) => ({
          indexPro: index,
          id: pro.mar_pro_id,
        }))
        .filter((item: any) => item.id !== null && item.id !== undefined)
    : [];

  const handleValidateStep = async () => {
    setButtonNextLoading(true);
    const response = await validateQuotes(worksiteData?.id || 0);
    if (response) {
      updateWorksiteData((prev) => ({
        ...prev,
        ...response,
      }));
      updateStepCompletion(
        STEPS_WORKSITE_MAR_COMPLETION.ADMINISTRATIVE_MANDATE
      );
    }
    setButtonNextLoading(false);
  };

  const preFillQuotesData = () => {
    if (worksiteData && worksiteData.pros && prosList.length > 0) {
      worksiteData.pros.forEach((pro, indexPro) => {
        const prefillQuotes = pro.devis.map((devis, indexDevis) => {
          if (devis.linked_file) {
            const linkedFile = devis.linked_file;
            createFileWithLinkedFile(
              linkedFile.file_url as string,
              linkedFile.file_name as string
            ).then((file) => {
              setValue(`pros[${indexPro}].quotes[${indexDevis}].file`, file);
            });
          }
          return {
            id: devis.id,
            date: devis.date,
            mpr_amount: devis.mpr_amount,
            selected_devis: devis.selected,
            local_primes: devis.local_primes,
          };
        });
        append({
          mar_pro_id: pro.id,
          quotes: prefillQuotes,
          selected_pro: pro.selected,
        });
      });
      setIsPrefilled(true);
      window.scroll(0, 0);
    } else if (
      worksiteData &&
      worksiteData.pros &&
      worksiteData.pros.length === 0
    ) {
      append({});
    }
  };

  const readyForWorksCertificateGeneration = () => {
    if (worksiteData?.pros) {
      const proSelected = worksiteData.pros.find((pro) => pro.selected);
      if (proSelected) {
        return proSelected.devis.some((devis) => devis.selected);
      }
    }
    return false;
  };

  const generateWorksCertificate = async () => {
    setLoadingGeneration(true);
    const response = await generateQuoteWorksCertificate(worksiteData?.id || 0);
    if (response && response.file_url) {
      downloadWhenGenerated(response.file_url, 'mar_works_certificate.pdf');
      updateWorksiteData((prev) => ({
        ...(prev as IWorksiteMar),
        linked_files: [...(prev as IWorksiteMar).linked_files, response],
      }));
    }
    setLoadingGeneration(false);
  };

  const handleAddPro = useCallback(() => {
    append({});
  }, [append]);

  const handleDeletePro = useCallback(
    async (indexPro: number) => {
      const quotesIds = worksiteData?.pros?.[indexPro]?.devis.map(
        (quote) => quote.id
      );
      if (quotesIds) {
        const response = await deleteQuotes(worksiteData?.id || 0, quotesIds);
        updateWorksiteData((prev) => ({
          ...(prev as IWorksiteMar),
          pros: response.data.pros,
        }));
      }
      remove(indexPro);
    },
    [remove]
  );

  useEffect(() => {
    updateButtonNextDisabled(!readyForWorksCertificateGeneration());
  }, [watch()]);

  useEffect(() => {
    if (operationsList && !isPrefilled && fields && fields.length === 0) {
      preFillQuotesData();
    }
  }, [prosList, operationsList]);

  return (
    <LayoutMarDetailsStep
      onValidate={handleValidateStep}
      buttonNextLoading={buttonNextLoading}
      buttonAdditionnal={
        <ButtonOpx
          label={`${t(
            'mar.worksite_creation.worksite_completion.folder_details.quotes.generate_work_attestation'
          )}`}
          type="tab"
          disabled={!readyForWorksCertificateGeneration()}
          onClick={generateWorksCertificate}
          isLoading={loadingGeneration}
          small
        />
      }
    >
      <div className="flex flex-col space-y-6">
        {!selectedScenario ? (
          <TextError
            errorMessage={t(
              'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.quote_section.error_indicate_which_audit'
            )}
            addClass="!mt-0 text-[.875rem]"
          />
        ) : (
          operationsList &&
          fields.map((fieldRecord, index) => (
            <OnePro
              key={fieldRecord.id}
              indexPro={index}
              nbPros={fields.length}
              onDelete={handleDeletePro}
              prosList={prosList.filter(
                (pro) =>
                  !marProIdList.some(
                    (item: any) => item.id === pro.id && item.indexPro !== index
                  )
              )}
              openByDefault={
                !worksiteData?.pros?.[index]?.devis?.some(
                  (devis) => devis.mpr_amount
                )
              }
            />
          ))
        )}
        <ButtonOpx
          label={`${t(
            'mar.worksite_creation.worksite_completion.folder_details.quotes.installer_section.add_installer'
          )}`}
          small
          onClick={handleAddPro}
          type="primary"
          addClass="w-full"
        />
      </div>
    </LayoutMarDetailsStep>
  );
}

export default Quotes;
